<template>
    <el-dialog
        class="add-todo-dialog"
        :visible.sync="visible"
        :title="title"
        :lock-scroll="false"
        :close-on-click-modal="false"
        :append-to-body="true"
        width="800px"
    >
        <el-form ref="form" :model="form" :show-message="false">
            <el-row>
                <el-form-item label="" prop="title" required>
                    <el-input
                        v-model.trim="form.title"
                        placeholder="输入待办事项"
                        max-length="100"
                    ></el-input>
                </el-form-item>
            </el-row>
            <el-row>
                <el-col :span="7">
                    <el-form-item label="优先级" prop="priority" required>
                        <el-select v-model="form.priority">
                            <el-option :value="30" label="高">高</el-option>
                            <el-option :value="20" label="中">中</el-option>
                            <el-option :value="10" label="低">低</el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :span="10">
                    <el-form-item label="时间段" prop="dateRange" required>
                        <el-date-picker
                            v-model="form.dateRange"
                            type="daterange"
                            range-separator="至"
                            start-placeholder="开始日期"
                            end-placeholder="结束日期"
                            format="yyyy-MM-dd"
                            value-format="yyyy-MM-dd"
                            :clearable="false"
                        >
                        </el-date-picker>
                    </el-form-item>
                </el-col>
                <!-- <el-col :span="7">
                    <el-form-item label="优先级">
                        <el-select disabled v-model="form.repeatId">
                            <el-option value="0" label="不重复"
                                >不重复</el-option
                            >
                        </el-select>
                    </el-form-item>
                </el-col> -->
            </el-row>
            <el-row>
                <el-form-item label="">
                    <el-input
                        v-model="form.description"
                        type="textarea"
                        :rows="4"
                        placeholder="描述一下"
                        maxlength="100"
                        show-word-limit
                    ></el-input>
                </el-form-item>
            </el-row>
        </el-form>
        <span slot="footer" class="dialog-footer">
            <el-button type="primary" @click="handleConfirm">保存</el-button>
            <el-button @click="handleCancel">取消</el-button>
        </span>
    </el-dialog>
</template>

<script>
import todoSvc from 'services/todo'
export default {
    name: 'AddPeriod',
    components: {},
    data () {
        return {
            visible: false,
            title: '新建待办',
            form: {
                repeatId: '0',
                priority: 20,
                dateRange: [new Date(), new Date()]
            },
            isEdit: false
        }
    },
    watch: {
        visible (newValue) {
            if (newValue) {
                this.$nextTick(() => {
                    this.$refs['form'].clearValidate()
                })
            }
        }
    },
    created () {},
    methods: {
        handleConfirm () {
            this.$refs['form'].validate(async valid => {
                if (valid) {
                    this.form.scheduleStart = this.form.dateRange[0]
                    this.form.scheduleEnd = this.form.dateRange[1]
                    let apiName = this.isEdit ? 'todoEdit' : 'addTodo'
                    if (this.isEdit) {
                        this.form = {
                            todoId: this.form.id,
                            title: this.form.title,
                            priority: this.form.priority,
                            scheduleStart: this.form.scheduleStart,
                            scheduleEnd: this.form.scheduleEnd,
                            description: this.form.description
                        }
                    }
                    await todoSvc[apiName](this.form)
                    this.form = {
                        repeatId: 0,
                        priority: 20,
                        dateRange: [new Date(), new Date()]
                    }
                    this.$message.success('保存成功！')
                    this.visible = false
                    // if (this.isEdit) {
                    this.$emit('handle-refresh')
                    // }
                } else {
                    console.log('error submit!!')
                    this.$message.warning('请完善表单内容！')
                    return false
                }
            })
        },
        handleCancel () {
            this.visible = false
        }
    }
}
</script>
<style lang="scss" scoped>
.add-todo-dialog {
    ::v-deep .el-form-item {
        display: flex;

        .el-form-item__label {
            width: 70px;
        }

        .el-form-item__content {
            flex: 1;
        }
    }

    .el-input__inner {
        width: 100%;
        border-radius: 0;
    }

    .el-select {
        width: 100%;
    }
    // .el-range-editor {
    //     width: 320px;
    // }
}
</style>
