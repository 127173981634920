<template>
    <div class="today__todo">
        <!-- 快捷添加待办 -->
        <div style="padding: 0 0.1rem; margin-bottom: 0.1rem;" class="today__todo--add">
            <add-todo-input ref="addInput" :is-add="true"></add-todo-input>
        </div>
        <!-- 今日待办列表 -->
        <div class="today__todo--main">
            <el-scrollbar style="height: 100%;">
                <div class="today__todo--pending" :class="{'today__todo--expand': isExpand}">
                    <template v-if="todoList && todoList.length >0">
                        <todo-list
                            ref="todo-list"
                            class="today__todo--list"
                            :todo-list="todoList"
                            :top-list="topList"
                            :not-top-list="notTopList"
                            @move-to-top="moveToTop"
                            @cancel-to-top="cancelStick"
                            @refresh-not-top-list="refreshNotTopList"
                            @handle-refresh-all="getTodoList"
                        ></todo-list>
                    </template>
                    <Empty v-else :show-img="true" text="暂无待办"></Empty>
                </div>
                <!-- 已处理待办 -->
                <HandledTodos @notice-expand="noticeExpand"></HandledTodos>
            </el-scrollbar>
        </div>
    </div>
</template>

<script>
import AddTodoInput from './components/addTodo'
import TodoList from './components/todoList'
import todoSvc from 'services/todo'
import Empty from '@/components/empty'
import HandledTodos from './handledTodos'
import oaSvc from 'services/oa'
import comSvc from 'services/common'
import bus from 'utils/eventBus'

export default {
    name: 'TodayTodoIndex',
    components: { TodoList, AddTodoInput, Empty, HandledTodos },
    data () {
        return {
            isExpand: false,
            topList: [], // 置顶待办
            notTopList: [] // 普通待办
        }
    },
    computed: {
    // 置顶&普通待办总和
        todoList () {
            return this.topList.concat(this.notTopList)
        }
        // hasData () {
        //     return this.topList && this.topList.length > 0 && this.notTopList && this.notTopList.length > 0
        // }
    },
    created () {
        this.getTodoList()
    },
    mounted () {
        bus.$on('refrsh-today-list', this.getTodoList)
    },
    methods: {
        // 获取今日待办 包括置顶和非置顶
        async getTodoList () {
            try {
                this.refreshTopList()
                // this.refreshNotTopList()
            } catch (error) {
                console.log(error)
            }
        },
        // 刷新置顶项
        async refreshTopList () {
            const { data = [] } = await todoSvc.todoTop()
            this.topList = data.map(item => {
                item.top = true
                return item
            })
            this.refreshNotTopList()
            this.markDelay(this.topList)
        },
        // 刷新普通待办列表
        async refreshNotTopList () {
            const { data = [] } = await todoSvc.todoNotTop()
            this.notTopList = data.slice(0, 10 - this.topList.length)
            this.markDelay(this.notTopList)
        },
        async moveToTop (id) {
            // 移入置顶
            await todoSvc.moveToTop({
                todoId: id
            })
            this.refreshTopList()
            this.refreshNotTopList()
        },
        // 取消置顶
        async cancelStick (id) {
            await todoSvc.moveFormTopToNotTop({
                todoId: id
            })
            this.refreshTopList()
            this.refreshNotTopList()
            // 刷新今日已处理列表
            bus.$emit('refrsh-handled-list')
        },
        markDelay (arr) {
            arr.forEach(item => {
                const scheduleEnd = this.$moment(item.scheduleEnd)
                const current = this.$moment()
                const diff = scheduleEnd.diff(current, 'days')
                if (item.status === 0 && diff < 0) {
                    item.delay = true
                }
            })
        },
        // 已处理待办 展示和隐藏
        noticeExpand (bol) {
            this.isExpand = bol
        }
    }
}
</script>
<style lang='scss' scoped>
.today__todo {
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: hidden;

    &--main {
        display: flex;
        flex: 1;
        flex-direction: column;
        overflow: auto;
    }

    ::v-deep .el-scrollbar__view {
        display: flex;
        flex-direction: column;
    }

    &--pending {
        flex: 1;
    }

    &--expand {
        flex: none;
        margin-bottom: 0.4rem;
    }
}
</style>
