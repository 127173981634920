<template>
    <el-dialog
        class="turn-other"
        :visible.sync="visible"
        :title="title"
        :lock-scroll="false"
        width="600px"
        :close-on-click-modal="false"
    >
        <h2>{{ todoInfo.title }}</h2>
        <p class="date">
            <!-- {{ todoInfo.scheduleStart.substr(0, 10) }}~{{
                todoInfo.scheduleEnd.substr(0, 10)
            }} -->
            {{ getDateRangeDisplay([todoInfo.scheduleStart,todoInfo.scheduleEnd]) }}
            <span
                :class="{
                    priority: true,
                    high: todoInfo.priority === 30,
                    mid: todoInfo.priority === 20,
                    low: todoInfo.priority === 10
                }"
            >{{ todoInfo.priority | priorityFilter }}</span>
        </p>
        <p class="description">{{ todoInfo.description }}</p>
        <el-form ref="form" :model="form" :show-message="false">
            <el-form-item label="转交给" required prop="employeeId">
                <el-select
                    v-model="form.employeeId"
                    clearable
                    filterable
                    @change="employeeChange"
                >
                    <el-option
                        v-for="item in provideObj.employeeList"
                        :key="Number(item.employeeId)"
                        :label="item.employeeName"
                        :value="Number(item.employeeId)"
                    >
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="转交原因">
                <el-input
                    v-model="form.reason"
                    type="textarea"
                    :rows="4"
                    maxlength="100"
                    show-word-limit
                ></el-input>
            </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
            <el-button type="primary" @click="handleConfirm">保存</el-button>
            <el-button @click="handleCancel">取消</el-button>
        </span>
    </el-dialog>
</template>

<script>
import todoSvc from 'services/todo'
import { getDateRangeDisplay } from 'utils/date'
export default {
    name: 'AddPeriod',
    filters: {
        priorityFilter: value => {
            switch (value) {
            case 10:
                return '低'
            case 20:
                return '中'
            case 30:
                return '高'
            }
        }
    },
    inject: ['provideObj'],
    data () {
        return {
            visible: false,
            title: '待办转交',
            form: {
                employeeId: null,
                reason: null,
                employeeName: null
            },
            todoInfo: {
                scheduleStart: '',
                scheduleEnd: ''
            }
        }
    },
    watch: {
        visible (newValue) {
            if (newValue) {
                this.$nextTick(() => {
                    // this.$refs.form.resetFields()
                    this.$refs.form.clearValidate()
                })
            } else {
                this.form = {
                    employeeId: null,
                    reason: null,
                    employeeName: null
                }
            }
        }
    },
    created () {},
    methods: {
        getDateRangeDisplay,
        handleConfirm () {
            this.$refs.form.validate(async valid => {
                if (valid) {
                    console.log(this.form)
                    await todoSvc.todoTurn({
                        employeeId: this.form.employeeId,
                        todoId: this.todoInfo.id,
                        reason: this.form.reason,
                        employeeName: this.form.employeeName
                    })
                    this.$message.success('转交成功！')
                    this.$emit('handle-refresh')
                    this.visible = false
                } else {
                    console.log('error submit')
                    this.$message.warning('请选择转交人！')
                    return false
                }
            })
        },
        handleCancel () {
            this.visible = false
        },
        employeeChange (val) {
            console.log(val)
            this.form.employeeName = this.provideObj.employeeList.find(
                item => Number(item.employeeId) === val
            ).employeeName
        }
    }
}
</script>
<style lang="scss" scoped>

.turn-other {
    ::v-deep .el-form {
        .el-input {
            width: 240px;
        }

        .el-textarea__inner,
        .el-input__inner {
            border-radius: 0;
        }
    }

    h2 {
        font-size: 18px;
        font-weight: 500;
        line-height: 44px;
        color: #333;
    }

    p.date {
        display: flex;
        align-items: center;
        font-size: 16px;
        line-height: 44px;
        color: #909399;

        > span {
            margin-left: 10px;
        }
    }

    p.description {
        margin-bottom: 10px;
        font-size: 14px;
        line-height: 32px;
        color: #909399;
    }

    span.priority {
        box-sizing: border-box;
        display: inline-block;
        height: 20px;
        padding: 3px;
        font-size: 12px;
        line-height: 14px;
        color: #fff;
        text-align: center;
        background-color: #80a3db;
        border-radius: 4px;
        transform: scale(0.9);
    }

    span.high {
        background-color: #df5a68;
    }

    span.mid {
        background-color: #e6a23c;
    }
}
</style>
