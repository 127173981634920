<template>
    <div class="dayover-list">
        <el-form class="page-search" :inline="true">
            <div class="left">
                <el-form-item v-show="!iscur">
                    <department
                        ref="dept"
                        v-model="searchForm.groupId"
                        placeholder="请选择组织"
                        api-name="getOwnDepts"
                        :is-cascader="true"
                        value-filed="id"
                        label-filed="groupName"
                        @change="querydata"
                    />
                </el-form-item>
                <el-form-item v-show="!iscur">
                    <gb-employee
                        ref="employee"
                        v-model="searchForm.employeeId"
                        clearable
                        api-name="getEmlpoyeesList"
                        placeholder="请选择员工"
                        :is-cascader="false"
                        :value-filed="'employeeId'"
                        :label-filed="'employeeName'"
                        @change="querydata"
                    ></gb-employee>
                </el-form-item>
                <el-form-item label="" :class="{ mine: iscur }">
                    <el-date-picker
                        v-model="dateValues"
                        type="daterange"
                        range-separator="至"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期"
                        format="yyyy-MM-dd"
                        value-format="yyyy-MM-dd"
                        :clearable="true"
                        @change="querydata"
                    >
                    </el-date-picker>
                </el-form-item>
                <!-- <el-form-item>
                    <el-select
                        v-model="titleLike"
                        filterable
                        clearable
                        remote
                        :remote-method="remoteLoadingMethod"
                        :loading="remoteLoading"
                        placeholder="查询选择待办内容"
                        @clear="getHistoryDay"
                    >
                        <el-option
                            class="custom-select-item"
                            v-for="item in todoList"
                            :key="item.id"
                            :label="item.title"
                            :value="item.id"
                            @click.native="handleListChange(item)"
                        >
                        </el-option>
                    </el-select>
                </el-form-item> -->
                <el-form-item>
                    <el-input
                        v-model="searchForm.titleLike"
                        placeholder="输入待办"
                        clearable
                        @clear="getHistoryDay"
                        @keyup.enter.native="querydata"
                    >
                    </el-input>
                </el-form-item>
                <el-form-item>
                    <el-checkbox
                        v-model="searchForm.onlyIncomplete"
                        @change="querydata"
                    >
                        仅未完成待办
                    </el-checkbox>
                </el-form-item>
                <el-form-item>
                    <el-checkbox
                        v-model="searchForm.onlyQuestion"
                        @change="querydata"
                    >
                        仅查看问题
                    </el-checkbox>
                </el-form-item>
            </div>
            <div class="right">
                <div class="search-btn">
                    <div class="custom-btn">
                        <el-button
                            type="primary"
                            @click="querydata"
                        >
                            查询
                        </el-button>
                        <el-button @click="reset">重置</el-button>
                    </div>
                </div>
            </div>
        </el-form>
        <div class="page-content">
            <div class="page-handle-btn tabs">
                <span
                    v-for="(tab, index) in tabs"
                    :key="index"
                    :class="{ actived: iscur === index }"
                    @click="changeTab(index)"
                >{{ tab }}</span>
            </div>
            <div class="gb-table">
                <el-table
                    ref="table"
                    :data="tableData"
                    height="500"
                    row-key="id"
                >
                    <!-- <el-table-column type="index">
                        <template slot="header">
                            <i
                                @click="showColumnSortDia"
                                class="el-icon-s-tools"
                            ></i>
                        </template>
                    </el-table-column> -->
                    <el-table-column
                        v-for="(column, index) in activeColumns"
                        :key="index"
                        :prop="column.prop"
                        :label="column.label"
                        :min-width="column.minWidth"
                        :formatter="column.formatter"
                        :align="column.align ? column.align : 'left'"
                        :show-overflow-tooltip="column.showTip"
                    >
                        <template slot-scope="scope">
                            <div
                                v-if="
                                    column.label === '日期' ||
                                        column.label === '人员姓名' ||
                                        column.label === '其他说明'
                                "
                            >
                                {{ scope.row[column.prop] }}
                            </div>
                            <div
                                v-else-if="column.label === '待办完成情况'"
                                class="condition"
                            >
                                <p
                                    v-for="item in scope.row.todoSummaryDetails"
                                    :key="item.id"
                                >
                                    <!-- <i
                                        v-if="item.todoStatus === 0"
                                        class="iconfont icon-weiwancheng"
                                    ></i>
                                    <i
                                        v-else-if="item.todoStatus === 1"
                                        class="iconfont icon-yiwancheng"
                                    ></i>
                                    <i
                                        v-else-if="item.todoStatus === 2"
                                        class="iconfont icon-yigezhi"
                                    ></i>
                                    <i
                                        v-else-if="item.todoStatus === -1"
                                        class="iconfont icon-yiquxiao"
                                    ></i> -->
                                    <template v-for="s in todoStatusDicts">
                                        <el-tooltip
                                            v-if="s.itemValue === item.todoStatus"
                                            :key="s.itemValue"
                                            class="item"
                                            effect="dark"
                                            :content="s.title"
                                            placement="top"
                                        >
                                            <i :class="s.class"></i>
                                        </el-tooltip>
                                    </template>
                                    {{ item.title }}
                                </p>
                            </div>
                            <div
                                v-else-if="column.label === '问题反馈'"
                                class="question"
                            >
                                <el-tooltip
                                    v-for="(item, index) in scope.row
                                        .todoSummaryQuestions"
                                    :key="item.title"
                                    effect="dark"
                                    :content="
                                        `${index + 1}. ${item.title} 协助人：${
                                            item.helperName
                                        }`
                                    "
                                    placement="top"
                                >
                                    <p>
                                        <!-- <span> -->
                                        {{
                                            `${index + 1}. ${
                                                item.title
                                            } 协助人：${item.helperName}`
                                        }}
                                    </p>
                                </el-tooltip>
                                <!-- </span> -->
                                <!-- <span> -->
                                <!-- </span> -->
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column label="操作" align="center">
                        <template slot-scope="scope">
                            <el-button
                                type="text"
                                @click="showDetail(scope.row.id)"
                            >
                                详情
                            </el-button>
                        </template>
                    </el-table-column> -->
                </el-table>
                <el-pagination
                    v-if="tableData && tableData.length"
                    :current-page="searchForm.current"
                    :page-sizes="[10, 20, 50, 100]"
                    :page-size="searchForm.size"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="total"
                    @current-change="handlesCurrentChange"
                    @size-change="handlesSizeChange"
                ></el-pagination>
            </div>
        </div>
        <column-sort-dia
            ref="columnSortDia"
            :columns="columns"
            @column-change="columnChange"
        ></column-sort-dia>
        <checkDialog
            ref="checkDialog"
            :sign="'detail'"
            :todo-status-dicts="todoStatusDicts"
        />
    </div>
</template>

<script>
import columnSortDia from '@/components/columnSortDialog'
import _ from 'lodash'
import department from '@/components/Department'
import utilsTable from '@/utils/table'
import checkDialog from './components/checkDialog'
import todoSvc from 'services/todo'
import comSvc from 'services/common'
import oaSvc from 'services/oa'
import gbEmployee from '@/components/Department'

export default {
    name: 'BudgetFill',
    components: { columnSortDia, department, checkDialog, gbEmployee },
    data () {
        return {
            /**
             *  hidden: false, 是否隐藏列
             *  showTip: true, 文字过长悬浮提示
             */
            columns: [
                {
                    prop: 'summaryDate',
                    label: '日期',
                    hidden: false,
                    width: 70
                },
                {
                    prop: 'employeeName',
                    label: '人员姓名',
                    hidden: false,
                    width: 70
                },
                {
                    prop: 'title',
                    label: '待办完成情况',
                    hidden: false,
                    minWidth: 150
                },
                {
                    prop: 'title',
                    label: '问题反馈',
                    hidden: false,
                    minWidth: 150
                },
                {
                    prop: 'remark',
                    label: '其他说明',
                    hidden: false,
                    minWidth: 150
                }
            ],
            searchForm: {
                current: 1,
                size: 20,
                groupId: null,
                todoId: null,
                employeeId: null,
                scheduleStart: null,
                scheduleEnd: null,
                onlyIncomplete: false,
                onlyQuestion: false
            },
            titleLike: '',
            todoList: [],
            remoteLoading: false,
            total: 0,
            dateValues: [],
            tableData: [],
            todoStatusDicts: [],
            employeeList: [],
            tabs: ['日清管理', '我的日清'],
            iscur: 0
        }
    },
    computed: {
        activeColumns () {
            return this.columns.filter(item => !item.hidden)
        }
        // dateValues: {
        //     get() {
        //         return [this.searchForm.dateFrom, this.searchForm.dateTo]
        //     },
        //     set(value) {
        //         this.searchForm.dateFrom = value ? value[0] : ''
        //         this.searchForm.dateTo = value ? value[1] : ''
        //     }
        // },
    },
    created () {
        // this.getDictionary()
        this.getTodoDictionary()
        this.getHistoryDay()
        this.getEmployeeList()
    },
    methods: {
        ...utilsTable,
        // 获取待办事项状态的数据字典
        async getTodoDictionary () {
            const { data = [] } = await comSvc.getDictionary('待办事项状态')
            this.todoStatusDicts = data.map((item) => {
                let obj = {}
                obj.itemValue = Number(item.itemValue)
                obj.title = item.title
                switch (obj.itemValue) {
                // 待处理
                case 0:
                    obj.class = 'iconfont icon-a-quandaibanweiwancheng'
                    break
                    // 已处理
                case 1:
                    obj.class = 'iconfont icon-yiwancheng'
                    break
                    // 已搁置
                case 2:
                    obj.class = 'iconfont icon-yigezhi'
                    break
                    // 已取消
                case -1:
                    obj.class = 'iconfont icon-yiquxiao'
                    break
                    // 已完成 待确认
                case 9:
                    obj.class = 'iconfont icon-yiwancheng assign'
                    break
                default:
                    obj.class = 'iconfont icon-a-quandaibanweiwancheng'
                    break
                }
                return obj
            })
        },
        changeTab (index) {
            this.iscur = index
            this.getHistoryDay()
        },
        // async getDictionary () {
        //     const { data = [] } = await comSvc.getDictionary('待办事项状态')
        //     this.todoStatusDicts = data.map(item => {
        //         let obj = {}
        //         obj.itemValue = Number(item.itemValue)
        //         obj.title = item.title
        //         return obj
        //     })
        //     console.log(this.todoStatusDicts)
        // },
        showColumnSortDia () {
            this.$refs.columnSortDia.visible = true
        },
        columnChange (val) {
            this.columns = _.cloneDeep(val)
            this.tableReLoayout('table')
        },
        querydata () {
            this.searchForm.current = 1
            this.getHistoryDay()
        },
        handlesCurrentChange (val) {
            this.searchForm.current = val
            this.getHistoryDay()
        },
        handlesSizeChange (val) {
            this.searchForm.current = 1
            this.searchForm.size = val
            this.getHistoryDay()
        },
        reset () {
            Object.keys(this.searchForm).forEach(item => {
                this.searchForm[item] = null
            })
            this.searchForm.size = 20
            this.searchForm.current = 1
            this.searchForm.onlyIncomplete = false
            this.searchForm.onlyQuestion = false
            this.dateValues = []
            this.titleLike = ''
            this.todoList = []
            this.$refs.employee.value = null
            this.$refs.employee.currentItem = {}
            this.$refs.dept.cascaderValue = null
            this.getHistoryDay()
        },
        showDayOverCheckDia () {
            this.$refs.checkDialog.visible = true
        },
        handleListChange (item) {
            this.searchForm.todoId = item.id
            this.getHistoryDay()
        },
        async getEmployeeList () {
            const { data = [] } = await oaSvc.getEmlpoyeesList()
            this.employeeList = data
        },
        async getHistoryDay () {
            this.searchForm.scheduleStart = this.dateValues
                ? this.dateValues[0]
                : null
            this.searchForm.scheduleEnd = this.dateValues
                ? this.dateValues[1]
                : null
            let query = {}
            if (this.iscur) {
                query.current = this.searchForm.current
                query.size = this.searchForm.size
                query.onlyIncomplete = this.searchForm.onlyIncomplete
                query.onlyQuestion = this.searchForm.onlyQuestion
                query.onlyQuestion = this.searchForm.onlyQuestion
                query.scheduleStart = this.searchForm.scheduleStart
                query.scheduleEnd = this.searchForm.scheduleEnd
                query.titleLike = this.searchForm.titleLike
                query.onlyMyDay = true
            } else {
                query = this.searchForm
            }
            const res = await todoSvc.getHistoryDay(query)
            this.tableData = res.data.map(item => {
                item.summaryDate = item.summaryDate
                    ? item.summaryDate.substr(0, 10)
                    : ''
                return item
            })
            this.total = res.total
        },
        async remoteLoadingMethod (query, row) {
            if (query !== '') {
                this.remoteLoading = true
                const req = {
                    titleLike: query,
                    current: 1,
                    size: 30
                }
                const { data = [] } = await todoSvc.getTodoListPage(req)
                this.todoList = data
                this.remoteLoading = false
            } else {
                this.todoList = []
            }
        },
        showDetail (id) {
            const _ref = this.$refs.checkDialog
            _ref.detailId = id
            _ref.employeeList = this.employeeList
            _ref.todoStatusDicts = this.todoStatusDicts
            _ref.visible = true
        }
    }
}
</script>
<style lang="scss" scoped>
.dayover-list {
    .el-icon-s-tools {
        cursor: pointer;
    }

    .el-form-item.mine {
        margin-left: 0 !important;
    }

    ::v-deep .el-table {
        i {
            margin-right: 10px;
            font-size: 0.2rem;
        }

        i.icon-a-quandaibanweiwancheng {
            color: #dcdfe6;
        }

        i.icon-yiwancheng {
            color: #52c123;
        }

        i.assign.icon-yiwancheng,
        i.icon-yigezhi {
            color: #e6a23c;
        }

        i.icon-yiquxiao,
        i.icon-weiwancheng {
            color: #df5a68;
        }

        .condition {
            > p {
                display: flex;
                align-items: center;
                overflow: hidden;
                font-size: 14px;
                line-height: 44px;
                color: #606266;
                text-overflow: ellipsis;
                white-space: nowrap;
            }
        }

        .question {
            > p {
                overflow: hidden;
                font-size: 14px;
                line-height: 36px;
                color: #606266;
                text-overflow: ellipsis;
                white-space: nowrap;
            }

            span + span {
                margin-left: 10px;
            }
        }
    }

    .tabs {
        // padding: 15px 0;
        height: 44px;
        font-size: 14px;
        font-weight: 400;
        color: #909399;

        span {
            display: inline-block;
            height: 32px;
            line-height: 32px;
            cursor: pointer;
        }

        span.actived {
            font-weight: 500;
            color: #3595e1;
            border-bottom: 2px solid #3595e1;
        }

        span + span {
            margin-left: 30px;
        }
    }
}
</style>
