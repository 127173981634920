import axios from 'axios'
import request from '@/utils/request'

const fetch = (
    path = '',
    params = {},
    restConfig = {
        method: 'post',
        'Content-Type': 'application/json'
    }
) =>
    request('todoServer', path, {
        params,
        ...restConfig
    })

export default {
    todoList () {},
    addTodo (query) {
        return axios.fetch('todoServer', '/todo/todo-list-add', query, 'json')
    },
    todoTop () {
        return fetch('/todo/todo-top-today', {
            dataType: 'form'
        })
    },
    // 查询已经解决的待办
    getHandledTodos (param) {
        return fetch('/todo/todo-list-handled', param)
    },
    todoNotTop () {
        return axios.fetch('todoServer', '/todo/todo-list-today')
    },
    getAllTodoList (query) {
        // 待办清单list
        return axios.fetch('todoServer', '/todo/todo-list-detail-page', query)
    },
    removeToNotTop (query) {
        return axios.fetch(
            'todoServer',
            '/todo/todo-list-detail-remove',
            query,
            'json'
        )
    },
    moveToTop (query) {
        // 今日待办移入置顶
        return axios.fetch(
            'todoServer',
            '/todo/todo-list-to-top',
            query,
            'json'
        )
    },
    moveFormTopToNotTop (query) {
        // 今日待办置顶移除
        return axios.fetch(
            'todoServer',
            '/todo/todo-list-top-remove',
            query,
            'json'
        )
    },
    moveFormNotTopToList (query) {
        // 今日待办移出
        return axios.fetch(
            'todoServer',
            '/todo/todo-list-today-remove',
            query,
            'json'
        )
    },
    topAndNotTopSort (query) {
        // 今日待办移出
        return axios.fetch(
            'todoServer',
            '/todo/todo-order-change',
            query,
            'json'
        )
    },
    todoComplete (query) {
        // 待办事项完成
        return axios.fetch(
            'todoServer',
            '/todo/todo-list-complete',
            query,
            'json'
        )
    },
    todoCancel (query) {
        // 待办事项取消
        return axios.fetch(
            'todoServer',
            '/todo/todo-list-cancel',
            query,
            'json'
        )
    },
    todoShelve (query) {
        // 待办事项搁置
        return axios.fetch(
            'todoServer',
            '/todo/todo-list-shelve',
            query,
            'json'
        )
    },
    todoTurn (query) {
        // 待办事项转交
        return axios.fetch(
            'todoServer',
            '/todo/todo-list-to-other',
            query,
            'json'
        )
    },
    reOpenTodo (query) {
        // 待办事项重新打开
        return axios.fetch('todoServer', '/todo/todo-open-new', query, 'json')
    },
    todoEdit (query) {
        // 待办事项修改
        return axios.fetch('todoServer', '/todo/todo-list-edit', query, 'json')
    },
    // /todo/todo-list-edit
    getHistoryDay (query) {
        // 获取历史日清
        return axios.fetch('todoServer', '/todo/history-day', query)
    },
    getTodoListPage (query) {
        // 获取历史日清
        return axios.fetch('todoServer', '/todo/todo-list-page', query, 'json')
    },
    getTodoItemDetail (query) {
        // 获取单个日清详情
        return axios.fetch('todoServer', '/todo/day-detail', query, 'json')
    },
    getHisTodoList (query) {
        // 历史待办page
        return axios.fetch('todoServer', '/todo/todo-list-history-page', query)
    },
    getDayCheckList (query) {
        // 日清检查查询
        return axios.fetch('todoServer', '/todo/day-check', query, 'json')
    },
    saveDayCheckList (query) {
        // 日清检查保存
        return axios.fetch('todoServer', '/todo/day-check-keep', query, 'json')
    },
    hisMoveToNotTop (query) {
        return axios.fetch(
            'todoServer',
            '/todo/todo-list-history-remove',
            query,
            'json'
        )
    },
    getYesterdayDayOvers (query) {
        return axios.fetch('todoServer', '/todo/yesterday-day', query)
    },
    // ----------核心关注------------

    getCoreFocusList () {
        // 核心关注list
        return fetch('/todo/core-follow')
    },
    getSomeoneTodoList (query) {
        // 获取某个人的今日待办
        return axios.fetch(
            'todoServer',
            '/todo/today-employee-todo',
            query,
            'json'
        )
    },
    saveSomeoneTodo (query) {
        // 今日待办(某人)保存
        return axios.fetch(
            'todoServer',
            '/todo/today-employee-todo-edit',
            query,
            'json'
        )
    },
    addAss (query) {
        // 协同增加
        return axios.fetch(
            'todoServer',
            '/todo/team-add',
            query,
            'json'
        )
    },
    delAss (query) {
        // 协同移除
        return axios.fetch(
            'todoServer',
            '/todo/team-remove',
            query,
            'json'
        )
    },
    // 待办详情
    getTodoDetail (param) {
        return fetch('/todo/todo-list-detail', param)
    },
    // 指派列表查询
    getTodoAssignList () {
        return fetch('/todo/todo-list-assign')
    },
    // 我参与的
    getTodoAssignInvolved () {
        return fetch('/todo/todo-list-involved')
    },
    // 指派未解决
    assignTodoNotResolve (param) {
        return fetch('/todo/todo-list-repulse', param)
    },
    getUntreatedTotal (params) {
        return fetch('/todo/todo-list-statistics', params)
    }
    // ------------end--------------
    // /todo/todo-list-repulse
}
