<template>
    <el-dropdown v-if="!isDetail" trigger="click" @command="handleCommand">
        <span
            class="el-dropdown-link priority"
            :class="{
                high: priority === 30,
                mid: priority === 20,
                low: priority === 10,
            }"
        >
            {{ priority | priorityFilter }}
        </span>
        <el-dropdown-menu slot="dropdown">
            <el-dropdown-item
                v-for="item in arr"
                :key="item.value"
                :command="item.value"
            >
                {{ item.label }}
            </el-dropdown-item>
        </el-dropdown-menu>
    </el-dropdown>
    <span
        v-else
        class="el-dropdown-link priority"
        :class="{
            high: priority === 30,
            mid: priority === 20,
            low: priority === 10,
        }"
    >
        {{ priority | priorityFilter }}
    </span>
</template>

<script>
export default {
    name: 'PriorityPopover',
    components: {},
    filters: {
        priorityFilter: (value) => {
            switch (value) {
            case 10:
                return '低'
            case 20:
                return '中'
            case 30:
                return '高'
            }
        }
    },
    props: {
        form: {
            type: Object,
            default: null
        },
        isDetail: Boolean
    },
    data () {
        return {
            priority: 20,
            arr: [
                { value: 30, label: '高' },
                { value: 20, label: '中' },
                { value: 10, label: '低' }
            ]
        }
    },
    created () {},
    methods: {
        handleCommand (command) {
            this.priority = command
            this.$emit('transformData', {
                priority: this.priority
            })
        },
        dataReset () {
            this.priority = 20
        },
        // 如果是修改 需要触发该函数 让优先级的值回显
        cloneFormData () {
            this.priority = this.form.priority
        }
    }
}
</script>
<style lang="scss" scoped>
.priority__value {
    display: inline-block;
}

span.priority {
    box-sizing: border-box;
    display: inline-block;
    width: 0.2rem;
    height: 0.2rem;
    // padding: 3px;
    font-size: 0.12rem;
    line-height: 0.2rem;
    color: #fff;
    text-align: center;
    cursor: pointer;
    border-radius: 4px;
    transform: scale(0.88);
}

span.high,
span.delay {
    background-color: #df5a68;
}

span.mid {
    background-color: #e6a23c;
}

span.low {
    background-color: #80a3db;
}
</style>
