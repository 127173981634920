<template>
    <div class="assign__item">
        <div class="assign__item--left">
            <template v-for="s in provideObj.todoStatusDicts">
                <el-tooltip
                    v-if="s.itemValue === itemData.status"
                    :key="s.itemValue"
                    class="item"
                    effect="dark"
                    :content="s.title"
                    placement="top"
                >
                    <i :class="s.class"></i>
                </el-tooltip>
            </template>
        </div>
        <div class="assign__item--middle">
            <TodoEditPopover
                :is-edit="isEdit"
                :is-detail="isDetail"
                :title="itemData.title"
                :todo-id="itemData.id"
            />
            <!-- <div v-else class="todo__title">
                {{ itemData.title }}
            </div> -->
            <p>
                <span class="todo__date">
                    {{
                        getDateRangeDisplay([
                            itemData.scheduleStart,
                            itemData.scheduleEnd,
                        ])
                    }}
                </span>
                <span class="todo__name">
                    {{ itemData.employeeName }}
                </span>
                <span v-if="itemData.fromType===1">
                    <el-tooltip
                        class="item"
                        effect="dark"
                        :content="itemData.fromName"
                        placement="top"
                    >
                        <i class="iconfont icon-kehu assign"></i>
                    </el-tooltip>
                </span>
            </p>
        </div>
        <div class="assign__item--right">
            <el-dropdown v-if="rightDropdownHandles">
                <i class="iconfont icon-gengduo1"></i>
                <el-dropdown-menu slot="dropdown">
                    <template v-for="h in rightDropdownHandles">
                        <el-dropdown-item
                            v-if="!h.status || h.status.includes(itemData.status)"
                            :key="h.label"
                            @click.native="handleCommand(h.label, itemData)"
                        >
                            {{ h.label }}
                        </el-dropdown-item>
                    </template>
                </el-dropdown-menu>
            </el-dropdown>
            <slot name="rightHandle"></slot>
        </div>
        <cacelTodoDialog
            ref="cacelTodoDialog"
            @handle-refresh="handleRefresh"
        />
    </div>
</template>

<script>
import TodoEditPopover from './todoEditPopover'
import { getDateRangeDisplay } from '@/utils/date'
import cacelTodoDialog from '@/modules/todo/dayPlan/components/cacelTodoDialog'
import _ from 'lodash'
import bus from 'utils/eventBus'
import todoSvc from 'services/todo'
import comSvc from 'services/common'

export default {
    name: 'TodoItem',
    components: { TodoEditPopover, cacelTodoDialog },
    props: {
        // 每一行的数据
        itemData: {
            type: Object,
            default: null
        },
        // 页面标识 例如是指派页面用该组件
        sign: {
            type: String,
            default: null
        },
        // 右侧操作菜单
        rightDropdownHandles: {
            type: Array,
            default: null
        },
        // 是否能够修改
        isEdit: Boolean,
        isDetail: Boolean
    },
    data () {
        return {
        }
    },
    inject: ['provideObj'],
    methods: {
        getDateRangeDisplay,
        // 点击菜单项后会触发事件，用户可以通过相应的菜单项 key 进行不同的操作
        async handleCommand (command, data) {
            console.log(command, data)
            let apiName = ''
            let tip = ''
            if (command === '未解决') {
                await todoSvc.assignTodoNotResolve({
                    todoId: data.id
                })
                // this.handleRefresh()
                this.$message.success('操作成功！')
                this.handleRefresh()
            } else if (command === '确认') {
                await todoSvc.todoComplete({
                    todoId: data.id
                })
                this.$message.success('操作成功！')
                this.handleRefresh()
            } else if (command === '重新打开') {
                await todoSvc.reOpenTodo({
                    id: data.id
                })
                this.$message.success('操作成功！')
                this.handleRefresh()
            } else if (command === '置顶') {
                // 移入置顶
                await todoSvc.moveToTop({
                    todoId: data.id
                })
                this.$message.success('操作成功！')
                this.handleRefresh()
            } else {
                switch (command) {
                case '搁置':
                    apiName = 'todoShelve'
                    tip = null
                    break
                case '取消':
                    apiName = 'todoCancel'
                    tip = null
                    break
                default:
                    break
                }
                const _ref = this.$refs.cacelTodoDialog
                _ref.apiName = apiName
                _ref.type = command
                _ref.tip = tip
                _ref.todoInfo = _.cloneDeep(data)
                _ref.form = {}
                _ref.visible = true
            }
        },
        // 刷新数据
        handleRefresh () {
            switch (this.sign) {
            case '指派':
                bus.$emit('refresh-assign-todo')
                break
            case '今日已处理':
                // 刷新今日待办列表
                bus.$emit('refrsh-today-list')
                // 刷新今日已处理列表
                bus.$emit('refrsh-handled-list')
                break

            default:
                break
            }
        }
    }
}
</script>
<style lang="less" scoped>
.assign__item {
    display: flex;
    align-items: center;

    &--left {
        i {
            font-size: 0.2rem;
        }

        i.icon-a-quandaibanweiwancheng {
            color: #dcdfe6;
        }

        i.icon-yiwancheng {
            color: #52c123;
        }

        i.assign.icon-yiwancheng,
        i.icon-yigezhi {
            color: #e6a23c;
        }

        i.icon-yiquxiao,
        i.icon-weiwancheng {
            color: #df5a68;
        }
    }

    &--middle {
        flex: 1;
        padding-left: 0.1rem;
        overflow: hidden;

        /deep/ .todo__title {
            max-width: 2.4rem;
            overflow: hidden;
            font-size: 0.14rem;
            font-weight: 400;
            line-height: 0.32rem;
            color: #606266;
            text-overflow: ellipsis;
            white-space: nowrap;
        }

        p:nth-child(2) {
            font-size: 0.14rem;
            font-weight: 400;
            color: #c0c4cc;

            > span + span {
                margin-left: 0.05rem;
            }

            i.assign {
                margin-left: 0.05rem;
                color: #69acf1;
                cursor: pointer;
            }
        }
    }

    &--right {
        i.icon-gengduo1 {
            cursor: pointer;
        }
    }
}
</style>
