<template>
    <el-popover
        v-if="!isDetail"
        v-model="visible"
        placement="bottom"
        @hide="handleHide"
        @show="handleShow"
    >
        <el-form label-width="100px">
            <div class="joiner__main">
                <el-form-item label="指派执行人">
                    <span v-if="!editable">
                        {{
                            executor && executor.employeeName
                                ? executor.employeeName
                                : ''
                        }}
                    </span>
                    <el-select
                        v-else
                        v-model="executor"
                        clearable
                        filterable
                        value-key="employeeId"
                        @clear="executorClear"
                    >
                        <el-option
                            v-for="item in provideObj.executorOption"
                            :key="item.employeeId"
                            :label="item.employeeName"
                            :value="item"
                        >
                        </el-option>
                    </el-select>
                    <el-tooltip
                        v-if="editExecutorTip"
                        class="item"
                        effect="dark"
                        :content="editExecutorTip"
                        placement="top"
                    >
                        <i class="el-icon-warning"></i>
                    </el-tooltip>
                </el-form-item>
                <el-form-item label="添加参与人">
                    <el-select
                        v-model="participants"
                        multiple
                        filterable
                        collapse-tags
                        value-key="employeeId"
                    >
                        <el-option
                            v-for="item in provideObj.employeeList"
                            :key="item.employeeId"
                            :label="item.employeeName"
                            :value="item"
                        >
                        </el-option>
                    </el-select>
                </el-form-item>
            </div>
            <div class="joiner__btns">
                <!-- <el-button class="custom-button" size="mini" @click="handleCancel">
                    取消
                </el-button> -->
                <el-button
                    class="custom-button"
                    type="primary"
                    size="mini"
                    @click="handleConfirm"
                >
                    确定
                </el-button>
            </div>
        </el-form>
        <span slot="reference" class="joiner__display">
            <i class="iconfont icon-kehu"></i>
            <el-tooltip
                v-if="fullStr"
                class="item"
                effect="dark"
                :content="fullStr"
                placement="top"
            >
                <span>{{ displayStr }}</span>
            </el-tooltip>
            <span v-else>{{ displayStr }}</span>
        </span>
    </el-popover>
    <div v-else class="joiner__display">
        <i class="iconfont icon-kehu"></i>
        <el-tooltip
            v-if="fullStr"
            class="item"
            effect="dark"
            :content="fullStr"
            placement="top"
        >
            <span>{{ displayStr }}</span>
        </el-tooltip>
        <span v-else>{{ displayStr }}</span>
    </div>
</template>

<script>
import oaSvc from 'services/oa'
import { mapGetters } from 'vuex'
export default {
    name: 'JoinerPopover',
    components: {},
    props: {
        form: {
            type: Object,
            default: null
        },
        isDetail: Boolean
    },
    inject: ['provideObj'],
    data () {
        return {
            visible: false, // 控制popover显示或隐藏
            // employeeList: [],
            displayStr: '-', // 规则：指派人显示再前面 后面的参与人超过1人时用等xx人
            fullStr: '', // 完整的字符串
            executor: {}, // 指定执行人
            participants: [], // 参与人
            editExecutorTip: '', // 修改指派时的提示
            editable: true
        }
    },

    computed: {
        ...mapGetters(['currentUser'])
    },
    watch: {
        visible (newValue) {
            if (newValue) {
                this.$nextTick(() => {
                    this.executor = this.form.executor
                    this.participants = this.form.participants
                    this.editExecutorTip = ''
                })
                // this.combineStr(this.executorId, this.participantIds)
            }
        },
        executor (val) {
            console.log(val)
            console.log(this.form.executor)
            if (
                this.form.executor &&
                val &&
                this.form.executor.employeeId != val.employeeId
            ) {
                this.editExecutorTip = val.employeeId
                    ? '待办将会出现在更改后的指派人的待办清单中，原指派人将不可见。'
                    : '将指派人删除后，可以在自己的日计划-待办清单中找到这条待办。'
            } else {
                this.editExecutorTip = ''
            }
        }
    },
    methods: {

        handleHide () {},
        handleShow () {},
        // 点击确认时
        handleConfirm () {
            if (
                !this.executor &&
                this.participants &&
                this.participants.length == 0
            ) {
                this.displayStr = '-'
            } else {
                this.combineStr(this.executor, this.participants)
            }
            this.$emit('transformData', {
                executor: this.executor,
                participants: this.participants
            })
            this.visible = false
        },
        handleCancel () {
            this.visible = false
        },
        // 根据id拼接员工姓名
        combineStr (executor, participants) {
            // 1.计算一共选中几个人
            let peopleCount = 0
            if (executor && executor.employeeId) {
                peopleCount += 1
            }
            if (participants && participants.length > 0) {
                peopleCount += participants.length
            }
            // 超过2人时 显示两个人姓名 然后 拼上 '等${peopleCount}人'
            if (peopleCount === 0) {
                this.fullStr = null
                this.displayStr = '-'
            } else if (peopleCount > 0 && peopleCount <= 2) {
                // 显示姓名拼接
                if (executor && executor.employeeId) {
                    console.log('=============')
                    if (participants && participants.length > 0) {
                        // 有参与人时
                        this.displayStr = this.fullStr =
                            executor.employeeName + ',' +
                            participants
                                .map((item) => item.employeeName)
                                .join(',')
                    } else {
                        // 没有参与人时
                        this.displayStr = this.fullStr = executor.employeeName
                    }
                } else {
                    this.displayStr = this.fullStr = participants
                        .map((item) => item.employeeName)
                        .join(',')
                }
            } else {
                // 超过2人时 显示两个人姓名 然后 拼上 '等${peopleCount}人'
                if (executor && executor.employeeId) {
                    this.fullStr =
                        executor.employeeName +
                        ',' +
                        participants.map((item) => item.employeeName).join(',')
                    this.displayStr =
                        executor.employeeName +
                        ',' +
                        participants[0].employeeName +
                        '等' +
                        peopleCount +
                        '人'
                } else {
                    // a.指派执行人执行人没有值
                    this.fullStr = participants
                        .map((item) => item.employeeName)
                        .join(',')
                    this.displayStr =
                        participants[0].employeeName +
                        ',' +
                        participants[1].employeeName +
                        '等' +
                        peopleCount +
                        '人'
                }
            }
        },
        // 重置数据
        resetDisplay () {
            this.displayStr = '-'
            this.fullStr = ''
        },
        // 修改回显
        cloneFormData () {
            this.executor = this.form.executor
            this.editable = this.form.editable
            this.participants = this.form.participants
            // console.log(this.executor)
            this.combineStr(this.executor, this.participants)
        },
        // 清空指派执行人
        executorClear () {
            this.executor = {}
        }
    }
}
</script>
<style lang="less" scoped>
.el-icon-warning {
    margin-left: 0.2rem;
    font-size: 0.24rem;
    color: #df5a68;
    cursor: pointer;
}

.joiner__display {
    display: flex;
    align-items: center;
    // margin-left: 0.08rem;
    font-size: 0.14rem;
    cursor: pointer;

    i {
        margin-right: 0.05rem;
        font-size: 0.16rem;
    }
}

.joiner__btns {
    width: 370px;
    text-align: right;
}
</style>
