var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.isDetail)?_c('el-dropdown',{attrs:{"trigger":"click"},on:{"command":_vm.handleCommand}},[_c('span',{staticClass:"el-dropdown-link priority",class:{
            high: _vm.priority === 30,
            mid: _vm.priority === 20,
            low: _vm.priority === 10,
        }},[_vm._v(" "+_vm._s(_vm._f("priorityFilter")(_vm.priority))+" ")]),_c('el-dropdown-menu',{attrs:{"slot":"dropdown"},slot:"dropdown"},_vm._l((_vm.arr),function(item){return _c('el-dropdown-item',{key:item.value,attrs:{"command":item.value}},[_vm._v(" "+_vm._s(item.label)+" ")])}),1)],1):_c('span',{staticClass:"el-dropdown-link priority",class:{
        high: _vm.priority === 30,
        mid: _vm.priority === 20,
        low: _vm.priority === 10,
    }},[_vm._v(" "+_vm._s(_vm._f("priorityFilter")(_vm.priority))+" ")])}
var staticRenderFns = []

export { render, staticRenderFns }