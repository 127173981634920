<template>
    <el-dialog
        class="day-over-check"
        :visible.sync="visible"
        :title="title"
        :lock-scroll="false"
        width="940px"
        :append-to-body="true"
        :close-on-click-modal="false"
    >
        <el-form
            v-if="hasData"
            ref="form"
            :model="form"
            :show-message="false"
            inline
        >
            <div class="todo">
                <div
                    v-for="(todoItem, i) in form.list"
                    :key="i"
                    :class="{ todoItem: true, topItem: todoItem.isStick }"
                >
                    <el-row class="item-content item-content_top">
                        <el-col :span="4">
                            <div class="title">
                                <!-- <p>{{ todoItem.title }}</p> -->
                                <el-tooltip
                                    v-if="todoItem.title.length > 15"
                                    class="item"
                                    effect="dark"
                                    :content="todoItem.title"
                                    placement="top"
                                >
                                    <p>{{ todoItem.title }}</p>
                                </el-tooltip>
                                <p v-else>{{ todoItem.title }}</p>
                                <p>
                                    {{
                                        getDateRangeDisplay([
                                            todoItem.scheduleStart,
                                            todoItem.scheduleEnd,
                                        ])
                                    }}
                                </p>
                            </div>
                            <div class="status">
                                <span>{{
                                    dicFilter(
                                        null,
                                        null,
                                        todoItem.todoStatus,
                                        null,
                                        todoStatusDicts
                                    )
                                }}</span>
                            </div>
                        </el-col>
                        <el-col :span="4">
                            <el-form-item :rules="rules.remark">
                                <el-input
                                    v-model="todoItem.remark"
                                    :disabled="form.isComplete"
                                    type="textarea"
                                    maxlength="100"
                                    show-word-limit
                                    :rows="4"
                                ></el-input>
                                <!-- <p v-else>
                                  反馈: {{ todoItem.remark }}
                                </p> -->
                            </el-form-item>
                        </el-col>
                        <el-col :span="2">
                            <span v-if="!form.isComplete" @click="addTodoFeedback(i)">
                                <i class="iconfont icon-benzhouxinzengfenxiaoshang"></i>添加反馈
                            </span>
                        </el-col>
                    </el-row>
                    <div
                        v-if="
                            todoItem.todoSummaryQuestions &&
                                todoItem.todoSummaryQuestions.length > 0
                        "
                        class="questions"
                    >
                        <el-row
                            v-for="(q, qIndex) in todoItem.todoSummaryQuestions"
                            :key="qIndex"
                            class="q-item item-content"
                        >
                            <!-- <el-col :span="4" class="q-item-main"> -->
                            <el-col :span="4">
                                <span class="index-number">{{ qIndex + 1 }}</span>
                                <div>
                                    <el-form-item
                                        label=""
                                        :prop="`list[${i}].todoSummaryQuestions[${qIndex}].title`"
                                        :rules="{
                                            required: true,
                                        }"
                                    >
                                        <el-input
                                            v-model="q.title"
                                            maxlength="15"
                                            :disabled="!isNaN(q.id)"
                                            show-word-limit
                                            placeholder="请输入问题项"
                                        ></el-input>
                                    </el-form-item>
                                    <el-form-item
                                        label=""
                                        :prop="`list[${i}].todoSummaryQuestions[${qIndex}].helperId`"
                                        :rules="{
                                            required: true,
                                        }"
                                    >
                                        <el-select
                                            v-model="q.helperId"
                                            :disabled="!isNaN(q.id)"
                                            clearable
                                            filterable
                                            placeholder="请选择协助人"
                                        >
                                            <el-option
                                                v-for="item in employeeList"
                                                :key="Number(item.employeeId)"
                                                :label="item.employeeName"
                                                :value="Number(item.employeeId)"
                                            >
                                            </el-option>
                                        </el-select>
                                    </el-form-item>
                                </div>
                            </el-col>
                            <el-col :span="4">
                                <el-form-item label="">
                                    <el-input
                                        v-model="q.description"
                                        :disabled="!isNaN(q.id)"
                                        type="textarea"
                                        :rows="4"
                                        maxlength="100"
                                        show-word-limit
                                        placeholder="请输入问题描述"
                                    ></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col v-if="!q.id" :span="2" class="q-item-del">
                                <span @click="delTodoFeedback(qIndex, i)">删除</span>
                            </el-col>
                        </el-row>
                    </div>
                </div>
            </div>
            <div class="no-relation-feedback">
                <div>
                    <span v-if="!form.isComplete" @click="addNoRelationFeedback()">
                        <i class="iconfont icon-benzhouxinzengfenxiaoshang"></i>其他问题
                    </span>
                </div>
                <el-row
                    v-for="(q, questionIndex) in form.questions"
                    :key="questionIndex"
                    class="q-item item-content"
                >
                    <el-col class="q-item-main">
                        <span class="index-number">{{ questionIndex + 1 }}</span>
                        <div>
                            <el-form-item
                                label=""
                                :prop="`questions[${questionIndex}].title`"
                                :rules="{
                                    required: true,
                                }"
                            >
                                <el-input
                                    v-model="q.title"
                                    maxlength="15"
                                    :disabled="!isNaN(q.id)"
                                    show-word-limit
                                    placeholder="请选择问题项"
                                ></el-input>
                            </el-form-item>
                            <el-form-item
                                label=""
                                :prop="`questions[${questionIndex}].helperId`"
                                :rules="{
                                    required: true,
                                }"
                            >
                                <el-select
                                    v-model="q.helperId"
                                    :disabled="!isNaN(q.id)"
                                    clearable
                                    filterable
                                    placeholder="请选择协助人"
                                >
                                    <el-option
                                        v-for="item in employeeList"
                                        :key="Number(item.employeeId)"
                                        :label="item.employeeName"
                                        :value="Number(item.employeeId)"
                                    >
                                    </el-option>
                                </el-select>
                            </el-form-item>
                        </div>
                    </el-col>
                    <el-col>
                        <el-form-item label="">
                            <el-input
                                v-model="q.description"
                                type="textarea"
                                :rows="4"
                                maxlength="100"
                                show-word-limit
                                placeholder="请输入问题描述"
                                :disabled="!isNaN(q.id)"
                            ></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col v-if="!q.id" class="q-item-del">
                        <span @click="noRelationQDel(questionIndex)">删除</span>
                    </el-col>
                </el-row>
            </div>
            <div class="otherDescription">
                <el-form-item label="其他说明">
                    <el-input
                        v-model="form.otherDescription"
                        :disabled="form.isComplete"
                        type="textarea"
                        :rows="3"
                        maxlength="100"
                        show-word-limit
                    ></el-input>
                </el-form-item>
            </div>
        </el-form>
        <Empty v-else style="height: 300px;" text="暂无日清" />
        <span v-if="hasData" slot="footer" class="dialog-footer">
            <el-button
                v-if="!form.isComplete"
                type="primary"
                @click="handleConfirm"
            >确定</el-button>
            <el-button @click="handleCancel">取消</el-button>
        </span>
        <span v-else slot="footer" class="dialog-footer">
            <el-button @click="handleCancel">取消</el-button>
        </span>
    </el-dialog>
</template>

<script>
import todoSvc from 'services/todo'
import _ from 'lodash'
import tableMethods from 'utils/table'
import Empty from 'components/empty'
import moment from 'moment'
import { getDateRangeDisplay } from 'utils/date'
export default {
    name: 'AddPeriod',
    components: {
        Empty
    },
    props: {
        sign: {
            type: String,
            default: ''
        }
    },
    data () {
        return {
            visible: false,
            title: '日清检查',
            form: {
                isComplete: false,
                topTodoList: [],
                otherTodoList: [],
                list: []
            },
            detailId: null,
            rules: {},
            employeeList: [],
            todoStatusDicts: [],
            hasData: true
        }
    },
    watch: {
        visible (newValue, oldValue) {
            if (newValue) {
                this.getDayCheckList()
            }
        }
    },
    methods: {
        moment,
        getDateRangeDisplay,
        ...tableMethods,
        // 获取日清检查弹框中的数据
        async getDayCheckList () {
            this.form = {}
            let apiName =
        this.sign === 'detail' ? 'getTodoItemDetail' : 'getDayCheckList'
            let param =
        this.sign === 'detail'
            ? {
                id: this.detailId
            }
            : null
            const { data = {} } = await todoSvc[apiName](param)
            this.hasData =
        this.isHasData(data, 'topTodoList') ||
        this.isHasData(data, 'otherTodoList')
            if (this.hasData) {
                data.topTodoList = data.topTodoList.map((item) => {
                    item.isStick = true
                    return item
                })
                data.list = [...data.topTodoList, ...data.otherTodoList]
                this.initListData(data.list, 'todoSummaryQuestions')
            } else {
                this.form.list = []
            }
            this.form = _.cloneDeep(data)
        },
        addTodoFeedback (i) {
            this.form.list[i].todoSummaryQuestions.push({
                helperName: '',
                description: '',
                title: ''
            })
        },
        delTodoFeedback (index, i) {
            this.form.list[i].todoSummaryQuestions.splice(index, 1)
        },
        handleConfirm () {
            // this.visible = false
            this.$refs.form.validate(async (valid) => {
                if (valid) {
                    //
                    // this.$confirm(
                    //     '每日日清检查仅可提交一次，提交后不可更改，是否确认提交？',
                    //     '提示',
                    //     {
                    //         confirmButtonText: '确定',
                    //         cancelButtonText: '取消',
                    //         type: 'warning'
                    //     }
                    // )
                    //     .then(async () => {
                    this.form.topTodoList = this.form.list.filter((item) => item.isStick)
                    this.form.otherTodoList = this.form.list.filter(
                        (item) => !item.isStick
                    )
                    await todoSvc.saveDayCheckList(this.form)
                    this.visible = false
                    this.$message({
                        type: 'success',
                        message: '保存成功!'
                    })
                    // })
                    // .catch(() => {
                    //     // this.$message({
                    //     //     type: 'info',
                    //     //     message: '已取消'
                    //     // })
                    // })
                } else {
                    console.log('error submit')
                    this.$message.warning('请完善表单！')
                }
            })
        },
        handleCancel () {
            this.visible = false
        },
        addNoRelationFeedback () {
            this.form.questions.push({
                helperName: '',
                description: '',
                title: ''
            })
        },
        noRelationQDel (i) {
            this.form.questions.splice(i, 1)
        },
        // 判断是否有数据
        isHasData (data, field) {
            let flag = true
            if (!data[field] && data[field].length > 0) flag = false
            return flag
        },
        // 初始化list数据
        // 以防后台返回的值中没有todoSummaryQuestions字段造成页面报错
        initListData (arr, field) {
            arr.forEach((item) => {
                if (!item[field]) {
                    item[field] = []
                }
            })
        }
    }
}
</script>
<style lang="scss" scoped>
.day-over-check {
    //
    ::v-deep .el-dialog {
        .el-textarea {
            width: 370px;
        }

        textarea,
        input {
            border-radius: 0;
        }

        .el-textarea__inner {
            height: 92px;
        }

        .el-select {
            width: 100%;
        }

        .el-dialog__body {
            max-height: 600px;
            overflow-y: auto;
        }

        .el-dialog__footer {
            padding: 10px;
            border-top: 2px solid #f0f0f0;

            .el-button {
                width: auto;
                height: auto;
                padding: 10px 18px;
                font-size: 14px;
                border-radius: 8px;
            }
        }

        .el-row.item-content {
            display: flex;
            align-items: center;
            // height: 64px;
            padding: 10px 0;

            .el-col {
                display: flex;
                align-items: center;
                height: 100%;

                .el-form-item {
                    width: 100%;
                    margin: 0;
                }

                .el-form-item__content {
                    width: 100%;
                }
            }

            .el-col:nth-child(1),
            .el-col:nth-child(2) {
                display: flex;
                width: 382px;
                padding-right: 15px;
            }

            .el-col:nth-child(1) {
                justify-content: space-between;
                padding-left: 30px;
            }

            .el-col:nth-child(3) {
                display: flex;
                flex: 1;
                align-items: center;
                justify-content: flex-end;
                padding-right: 30px;
                font-size: 14px;
                color: #3595e1;

                i {
                    margin-right: 10px;
                    font-size: 12px;
                }

                span {
                    cursor: pointer;
                }
            }
        }

        .topItem {
            .el-row.item-content_top::before {
                position: absolute;
                top: 0;
                left: 0;
                content: "";
                border-top: 15px solid #df5a68;
                border-right: 15px solid rgba(255, 255, 255, 1);
                border-top-left-radius: 3px;
                border-top-right-radius: 3px;
                border-top-right-radius: 3px;
                border-bottom-left-radius: 3px;
                border-bottom-left-radius: 3px;
            }
        }

        .todo,
        .other {
            .todoItem {
                border-bottom: 1px solid #f0f0f0;
            }

            .title {
                flex: 1;
                overflow: hidden;
            }

            .title > p:nth-child(1) {
                overflow: hidden;
                font-size: 16px;
                font-weight: 500;
                color: #606266;
                text-overflow: ellipsis;
                white-space: nowrap;
            }

            .title > p:nth-child(2) {
                font-size: 14px;
                font-weight: 400;
                line-height: 24px;
                color: #c0c4cc;
            }

            .status {
                width: 50px;
                height: 20px;
                font-size: 12px;
                line-height: 20px;
                color: #fff;
                text-align: center;
                background-color: #80a3db;
                border-radius: 4px;
                transform: scale(0.9);
            }
        }

        .questions,
        .no-relation-feedback {
            .index-number {
                position: absolute;
                top: 10px;
                left: 0;
                display: flex;
                padding: 0 10px;
            }

            .q-item {
                .el-form-item {
                    margin-bottom: 10px !important;
                }

                .q-item-del {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
            }
        }

        .no-relation-feedback {
            border-bottom: 1px solid #f0f0f0;

            > div:nth-child(1) {
                height: 44px;
                padding-left: 20px;
                margin-bottom: 15px;
                font-size: 14px;
                line-height: 44px;
                color: #3595e1;

                i {
                    margin-right: 10px;
                    font-size: 12px;
                }

                span {
                    cursor: pointer;
                }
            }
        }

        .otherDescription {
            padding-left: 20px;
            margin-top: 20px;

            .el-form-item {
                width: 100%;
            }
        }
    }
}
</style>
