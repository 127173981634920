var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('el-dialog',{staticClass:"day-over-check",attrs:{"visible":_vm.visible,"title":_vm.title,"lock-scroll":false,"width":"940px","append-to-body":true,"close-on-click-modal":false},on:{"update:visible":function($event){_vm.visible=$event}}},[(_vm.hasData)?_c('el-form',{ref:"form",attrs:{"model":_vm.form,"show-message":false,"inline":""}},[_c('div',{staticClass:"todo"},_vm._l((_vm.form.list),function(todoItem,i){return _c('div',{key:i,class:{ todoItem: true, topItem: todoItem.isStick }},[_c('el-row',{staticClass:"item-content item-content_top"},[_c('el-col',{attrs:{"span":4}},[_c('div',{staticClass:"title"},[(todoItem.title.length > 15)?_c('el-tooltip',{staticClass:"item",attrs:{"effect":"dark","content":todoItem.title,"placement":"top"}},[_c('p',[_vm._v(_vm._s(todoItem.title))])]):_c('p',[_vm._v(_vm._s(todoItem.title))]),_c('p',[_vm._v(" "+_vm._s(_vm.getDateRangeDisplay([ todoItem.scheduleStart, todoItem.scheduleEnd ]))+" ")])],1),_c('div',{staticClass:"status"},[_c('span',[_vm._v(_vm._s(_vm.dicFilter( null, null, todoItem.todoStatus, null, _vm.todoStatusDicts )))])])]),_c('el-col',{attrs:{"span":4}},[_c('el-form-item',{attrs:{"rules":_vm.rules.remark}},[_c('el-input',{attrs:{"disabled":_vm.form.isComplete,"type":"textarea","maxlength":"100","show-word-limit":"","rows":4},model:{value:(todoItem.remark),callback:function ($$v) {_vm.$set(todoItem, "remark", $$v)},expression:"todoItem.remark"}})],1)],1),_c('el-col',{attrs:{"span":2}},[(!_vm.form.isComplete)?_c('span',{on:{"click":function($event){return _vm.addTodoFeedback(i)}}},[_c('i',{staticClass:"iconfont icon-benzhouxinzengfenxiaoshang"}),_vm._v("添加反馈 ")]):_vm._e()])],1),(
                        todoItem.todoSummaryQuestions &&
                            todoItem.todoSummaryQuestions.length > 0
                    )?_c('div',{staticClass:"questions"},_vm._l((todoItem.todoSummaryQuestions),function(q,qIndex){return _c('el-row',{key:qIndex,staticClass:"q-item item-content"},[_c('el-col',{attrs:{"span":4}},[_c('span',{staticClass:"index-number"},[_vm._v(_vm._s(qIndex + 1))]),_c('div',[_c('el-form-item',{attrs:{"label":"","prop":("list[" + i + "].todoSummaryQuestions[" + qIndex + "].title"),"rules":{
                                        required: true,
                                    }}},[_c('el-input',{attrs:{"maxlength":"15","disabled":!isNaN(q.id),"show-word-limit":"","placeholder":"请输入问题项"},model:{value:(q.title),callback:function ($$v) {_vm.$set(q, "title", $$v)},expression:"q.title"}})],1),_c('el-form-item',{attrs:{"label":"","prop":("list[" + i + "].todoSummaryQuestions[" + qIndex + "].helperId"),"rules":{
                                        required: true,
                                    }}},[_c('el-select',{attrs:{"disabled":!isNaN(q.id),"clearable":"","filterable":"","placeholder":"请选择协助人"},model:{value:(q.helperId),callback:function ($$v) {_vm.$set(q, "helperId", $$v)},expression:"q.helperId"}},_vm._l((_vm.employeeList),function(item){return _c('el-option',{key:Number(item.employeeId),attrs:{"label":item.employeeName,"value":Number(item.employeeId)}})}),1)],1)],1)]),_c('el-col',{attrs:{"span":4}},[_c('el-form-item',{attrs:{"label":""}},[_c('el-input',{attrs:{"disabled":!isNaN(q.id),"type":"textarea","rows":4,"maxlength":"100","show-word-limit":"","placeholder":"请输入问题描述"},model:{value:(q.description),callback:function ($$v) {_vm.$set(q, "description", $$v)},expression:"q.description"}})],1)],1),(!q.id)?_c('el-col',{staticClass:"q-item-del",attrs:{"span":2}},[_c('span',{on:{"click":function($event){return _vm.delTodoFeedback(qIndex, i)}}},[_vm._v("删除")])]):_vm._e()],1)}),1):_vm._e()],1)}),0),_c('div',{staticClass:"no-relation-feedback"},[_c('div',[(!_vm.form.isComplete)?_c('span',{on:{"click":function($event){return _vm.addNoRelationFeedback()}}},[_c('i',{staticClass:"iconfont icon-benzhouxinzengfenxiaoshang"}),_vm._v("其他问题 ")]):_vm._e()]),_vm._l((_vm.form.questions),function(q,questionIndex){return _c('el-row',{key:questionIndex,staticClass:"q-item item-content"},[_c('el-col',{staticClass:"q-item-main"},[_c('span',{staticClass:"index-number"},[_vm._v(_vm._s(questionIndex + 1))]),_c('div',[_c('el-form-item',{attrs:{"label":"","prop":("questions[" + questionIndex + "].title"),"rules":{
                                required: true,
                            }}},[_c('el-input',{attrs:{"maxlength":"15","disabled":!isNaN(q.id),"show-word-limit":"","placeholder":"请选择问题项"},model:{value:(q.title),callback:function ($$v) {_vm.$set(q, "title", $$v)},expression:"q.title"}})],1),_c('el-form-item',{attrs:{"label":"","prop":("questions[" + questionIndex + "].helperId"),"rules":{
                                required: true,
                            }}},[_c('el-select',{attrs:{"disabled":!isNaN(q.id),"clearable":"","filterable":"","placeholder":"请选择协助人"},model:{value:(q.helperId),callback:function ($$v) {_vm.$set(q, "helperId", $$v)},expression:"q.helperId"}},_vm._l((_vm.employeeList),function(item){return _c('el-option',{key:Number(item.employeeId),attrs:{"label":item.employeeName,"value":Number(item.employeeId)}})}),1)],1)],1)]),_c('el-col',[_c('el-form-item',{attrs:{"label":""}},[_c('el-input',{attrs:{"type":"textarea","rows":4,"maxlength":"100","show-word-limit":"","placeholder":"请输入问题描述","disabled":!isNaN(q.id)},model:{value:(q.description),callback:function ($$v) {_vm.$set(q, "description", $$v)},expression:"q.description"}})],1)],1),(!q.id)?_c('el-col',{staticClass:"q-item-del"},[_c('span',{on:{"click":function($event){return _vm.noRelationQDel(questionIndex)}}},[_vm._v("删除")])]):_vm._e()],1)})],2),_c('div',{staticClass:"otherDescription"},[_c('el-form-item',{attrs:{"label":"其他说明"}},[_c('el-input',{attrs:{"disabled":_vm.form.isComplete,"type":"textarea","rows":3,"maxlength":"100","show-word-limit":""},model:{value:(_vm.form.otherDescription),callback:function ($$v) {_vm.$set(_vm.form, "otherDescription", $$v)},expression:"form.otherDescription"}})],1)],1)]):_c('Empty',{staticStyle:{"height":"300px"},attrs:{"text":"暂无日清"}}),(_vm.hasData)?_c('span',{staticClass:"dialog-footer",attrs:{"slot":"footer"},slot:"footer"},[(!_vm.form.isComplete)?_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.handleConfirm}},[_vm._v("确定")]):_vm._e(),_c('el-button',{on:{"click":_vm.handleCancel}},[_vm._v("取消")])],1):_c('span',{staticClass:"dialog-footer",attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{on:{"click":_vm.handleCancel}},[_vm._v("取消")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }