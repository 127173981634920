<template>
    <div id="draggle-container" style="position: relative;">
        <!-- 置顶 -->
        <draggable-list
            v-if="topList && topList.length > 0"
            :list.sync="topList"
            group-name="stick"
            @todo-sort="onEnd"
            @todo-handle="handle"
            @handle-refresh="getSeletedTodoList"
            @cancelStick="cancelStick"
        ></draggable-list>
        <!-- 非置顶 -->
        <draggable-list
            v-if="notTopList && notTopList.length > 0"
            :list.sync="notTopList"
            group-name="notStick"
            @todo-sort="onEnd"
            @todo-handle="handle"
            @handle-refresh="getSeletedTodoList"
            @moveToStick="moveToStick"
        >
        </draggable-list>
        <addTodoDailog ref="addTodoDailog" @handle-refresh="getSeletedTodoList" />
        <turnToOther ref="turnToOther" @handle-refresh="getSeletedTodoList" />
        <cacelTodoDialog
            ref="cacelTodoDialog"
            @handle-refresh="getSeletedTodoList"
        />
    </div>
</template>

<script>
import DraggableList from './draggableList'
import todoSvc from 'services/todo'
import _ from 'lodash'
import addTodoDailog from '@/modules/todo/dayPlan/components/addTodoDailog'
import cacelTodoDialog from '@/modules/todo/dayPlan/components/cacelTodoDialog'
import turnToOther from '@/modules/todo/dayPlan/components/turnToOther'

export default {
    name: 'TodoList',
    components: { DraggableList, addTodoDailog, turnToOther, cacelTodoDialog },
    props: {
        todoList: {
            type: Array,
            default: null
        },
        topList: {
            type: Array,
            default: null
        },
        notTopList: {
            type: Array,
            default: null
        }
    },
    data () {
        return {
        }
    },
    created () {},
    methods: {
        async handle (type, item) {
            if (type === '转交') {
                // turnToOther
                const _ref = this.$refs.turnToOther
                _ref.todoInfo = _.cloneDeep(item)
                // _ref.employeeList = this.employeeList
                _ref.visible = true
            } else if (type === '编辑') {
                // 编辑
                const _ref = this.$refs.addTodoDailog
                _ref.form = _.cloneDeep(item)
                this.$set(_ref.form, 'dateRange', [
                    this.$moment(item.scheduleStart).format('YYYY-MM-DD'),
                    this.$moment(item.scheduleEnd).format('YYYY-MM-DD')
                ])
                _ref.title = '编辑待办'
                _ref.isEdit = true
                _ref.visible = true
            } else if (type === '重新打开') {
                await todoSvc.reOpenTodo({
                    id: item.id
                })
                this.getSeletedTodoList()
            } else {
                let apiName = ''
                let tip = ''
                switch (type) {
                case '取消':
                    apiName = 'todoCancel'
                    tip =
              '取消后将不会自动进入每日待办，当需要恢复，可以在日计划中找到这个待办，手动恢复为待处理'
                    break
                case '搁置':
                    apiName = 'todoShelve'
                    tip =
              '搁置后将不会自动进入每日待办，当需要重新处理时，可以在日计划中找到这个待办，手动重新打开为待处理'
                    break
                }
                const _ref = this.$refs.cacelTodoDialog
                _ref.apiName = apiName
                _ref.type = type
                _ref.tip = tip
                _ref.todoInfo = _.cloneDeep(item)
                _ref.form = {}
                _ref.visible = true
            }
        },
        // 刷新全部待办
        getSeletedTodoList () {
            this.$emit('handle-refresh-all')
        },
        // 置顶
        moveToStick (id) {
            console.log(this.topList.length >= 3)
            if (this.topList.length >= 3) {
                return this.$message.warning('替换置顶请先将当前置顶取消，置顶只有三项不要贪多哦！')
            }
            this.$emit('move-to-top', id)
        },
        // 取消置顶
        cancelStick (id) {
            this.$emit('cancel-to-top', id)
        },
        async onEnd (val) {
            try {
                await todoSvc.topAndNotTopSort(val)
            } catch (error) {
                console.log(error)
            }
        }
    }
}
</script>
<style lang='less' scoped>
.contextmenu {
    position: absolute;
    z-index: 99999;
    padding: 0.05rem;
    margin: 0;
    font-size: 0.12rem;
    font-weight: 400;
    color: #333;
    list-style-type: none;
    background: #f8f5f5;
    border-radius: 4px;
    box-shadow: 2px 2px 3px 0 rgba(0, 0, 0, 0.3);

    li {
        z-index: 99999;
        width: 1rem;
        padding: 0.07rem 0.16rem;
        margin: 0;
        cursor: pointer;

        &:hover {
            background: #eee;
        }
    }
}
</style>
