<template>
    <el-dialog
        class="turn-other"
        :visible.sync="visible"
        :title="type"
        :lock-scroll="false"
        width="600px"
        :close-on-click-modal="false"
    >
        <h2>{{ todoInfo.title }}</h2>
        <p class="date">
            <!-- {{ todoInfo.scheduleStart.substr(0, 10) }}~{{
                todoInfo.scheduleEnd.substr(0, 10)
            }} -->
            {{ getDateRangeDisplay([todoInfo.scheduleStart,todoInfo.scheduleEnd]) }}
            <span
                :class="{
                    priority: true,
                    high: todoInfo.priority === 30,
                    mid: todoInfo.priority === 20,
                    low: todoInfo.priority === 10
                }"
            >{{ todoInfo.priority | priorityFilter }}</span>
        </p>
        <p class="description">{{ todoInfo.description }}</p>
        <p v-if="tip" class="tip">
            <i class="el-icon-warning"></i>
            <span>{{ tip }}</span>
        </p>
        <div v-if="type==='取消'&&todoInfo.repeatId!=0" class="onlyThis">
            <el-checkbox v-model="onlyThis"></el-checkbox>
            <span>仅取消本次，下次正常重复</span>
        </div>
        <el-form ref="form" :model="form" :show-message="false">
            <el-form-item :label="`${type}原因`">
                <el-input
                    v-model="form.reason"
                    type="textarea"
                    :rows="4"
                    maxlength="100"
                    show-word-limit
                ></el-input>
            </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
            <el-button type="primary" @click="handleConfirm">保存</el-button>
            <el-button @click="handleCancel">取消</el-button>
        </span>
    </el-dialog>
</template>

<script>
import todoSvc from 'services/todo'
import { getDateRangeDisplay } from 'utils/date'
import bus from 'utils/eventBus'
export default {
    name: 'AddPeriod',
    filters: {
        priorityFilter: value => {
            switch (value) {
            case 10:
                return '低'
            case 20:
                return '中'
            case 30:
                return '高'
            }
        }
    },
    data () {
        return {
            visible: false,
            title: '完成反馈',
            form: {},
            todoInfo: {
                scheduleStart: '',
                scheduleEnd: ''
            },
            type: '',
            apiName: 'todoShelve',
            tip: '',
            onlyThis: true // 是否仅取消本次
        }
    },
    methods: {
        getDateRangeDisplay,
        handleConfirm () {
            this.$refs.form.validate(async valid => {
                if (valid) {
                    console.log(this.form)
                    const param = {
                        todoId: this.todoInfo.id,
                        reason: this.form.reason
                    }
                    if (this.todoInfo.repeatId != 0 && this.type == '取消') {
                        param.onlyThis = this.onlyThis
                    }
                    await todoSvc[this.apiName](param)
                    this.$message.success(this.type + '完成！')
                    this.$emit('handle-refresh')
                    // 刷新我指派的待办列表
                    bus.$emit('refresh-assign-todo')
                    // 刷新今日已处理列表
                    bus.$emit('refrsh-handled-list')
                    this.visible = false
                } else {
                    console.log('error submit')
                    return false
                }
            })
        },
        handleCancel () {
            this.visible = false
        }
    }
}
</script>
<style lang="scss" scoped>
.turn-other {
    ::v-deep .el-form {
        .el-input {
            width: 240px;
        }

        .el-textarea__inner,
        .el-input__inner {
            border-radius: 0;
        }
    }

    h2 {
        font-size: 18px;
        font-weight: 500;
        line-height: 44px;
        color: #333;
    }

    p.date {
        display: flex;
        align-items: center;
        font-size: 16px;
        line-height: 44px;
        color: #909399;

        > span {
            margin-left: 10px;
        }
    }

    p.description {
        font-size: 14px;
        line-height: 32px;
        color: #909399;
    }

    p.tip {
        display: flex;
        align-items: center;

        i {
            margin-right: 20px;
            font-size: 20px;
            color: #e6a23c;
        }

        span {
            line-height: 24px;
            color: #909399;
        }
    }

    ::v-deep .onlyThis {
        padding-left: 4px;
        line-height: 44px;

        >span {
            margin-left: 20px;
            color: #909399;
        }
    }

    span.priority {
        box-sizing: border-box;
        display: inline-block;
        height: 20px;
        padding: 3px;
        font-size: 12px;
        line-height: 14px;
        color: #fff;
        text-align: center;
        background-color: #80a3db;
        border-radius: 4px;
        transform: scale(0.9);
    }

    span.high {
        background-color: #df5a68;
    }

    span.mid {
        background-color: #e6a23c;
    }
}
</style>
