<template>
    <div>
        <draggable
            v-model="listSync"
            tag="ul"
            :group="groupName"
            class="todo-list"
            animation="300"
            @end="onEnd"
        >
            <li
                v-for="(item, index) in listSync"
                :key="item.id"
                :class="{ top: item.top, 'J-shoping-list-a': true }"
            >
                <i v-if="item.top" class="iconfont icon-zhidingjiaobiao"></i>
                <span v-if="item.top" class="status-icon">
                    <!-- <i
                        v-if="item.todoStatus === 0"
                        class="iconfont icon-weiwancheng"
                    ></i>
                    <i
                        v-else-if="item.status === 1"
                        class="iconfont icon-yiwancheng"
                    ></i>
                    <i v-else-if="item.status === 2" class="iconfont icon-yigezhi"></i>
                    <i
                        v-else-if="item.status === 9"
                        class="iconfont icon-yiwancheng assign"
                    ></i>
                    <i
                        v-else-if="item.status === -1"
                        class="iconfont icon-yiquxiao"
                    ></i> -->
                    <!-- <div v-if="item.status !== 0"> -->
                    <template v-for="s in provideObj.todoStatusDicts">
                        <el-tooltip
                            v-if="s.itemValue === item.status"
                            :key="s.itemValue"
                            class="item"
                            effect="dark"
                            :content="s.title"
                            placement="top"
                        >
                            <i v-if="item.status !== 0" :class="s.class"></i>
                            <el-checkbox
                                v-else
                                @change="handleComplete(item, index)"
                            ></el-checkbox>
                        </el-tooltip>
                    </template>
                    <!-- </div> -->
                    <!-- <el-checkbox
                        v-else
                        @change="handleComplete(item, index)"
                    ></el-checkbox> -->
                </span>
                <span v-else class="status-icon">
                    <el-checkbox
                        @change="handleComplete(item, index)"
                    ></el-checkbox>
                </span>
                <div class="middle">
                    <TodoEditPopover
                        :is-edit="true"
                        :title="item.title"
                        :todo-id="item.id"
                    />
                    <p>
                        {{
                            getDateRangeDisplay([
                                item.scheduleStart,
                                item.scheduleEnd,
                            ])
                        }}
                        <span v-if="item.fromType === 1">
                            <el-tooltip
                                class="item"
                                effect="dark"
                                :content="item.fromName"
                                placement="top"
                            >
                                <i class="iconfont icon-kehu assign"></i>
                            </el-tooltip>
                        </span>
                    </p>
                </div>
                <!-- <slot name="dropdownHandle">

                </slot> -->
                <el-dropdown>
                    <i class="iconfont icon-gengduo1"></i>
                    <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item
                            v-if="!item.top"
                            @click.native="moveToStick(item.id)"
                        >
                            置顶
                        </el-dropdown-item>
                        <el-dropdown-item
                            v-if="item.top"
                            @click.native="cancelStick(item.id)"
                        >
                            取消置顶
                        </el-dropdown-item>
                        <el-dropdown-item
                            v-if="item.status === 0"
                            @click.native="handle('转交', item)"
                        >
                            转交
                        </el-dropdown-item>
                        <el-dropdown-item
                            v-if="item.status !== 0"
                            @click.native="handle('重新打开', item)"
                        >
                            重新打开
                        </el-dropdown-item>
                        <el-dropdown-item
                            v-if="item.status === 0"
                            @click.native="handle('取消', item)"
                        >
                            取消
                        </el-dropdown-item>
                        <el-dropdown-item
                            v-if="item.status === 0"
                            @click.native="handle('搁置', item)"
                        >
                            搁置
                        </el-dropdown-item>
                        <!-- <el-dropdown-item @click.native="handle('编辑', item)">
                            编辑
                        </el-dropdown-item> -->
                    </el-dropdown-menu>
                </el-dropdown>
            </li>
        </draggable>
    </div>
</template>

<script>
import draggable from 'vuedraggable'
import { getDateRangeDisplay } from '@/utils/date'
import _ from 'lodash'
import todoSvc from 'services/todo'
import TodoEditPopover from './todoEditPopover'
import bus from 'utils/eventBus'
export default {
    name: 'DraggableList',
    components: { TodoEditPopover },
    props: {
        list: {
            type: Array,
            default: null
        },
        groupName: {
            type: String,
            required: true,
            default: null
        }
    },
    data () {
        return {
            listSync: []
        }
    },
    inject: ['provideObj'],
    watch: {
        list: {
            immediate: true,
            deep: true,
            handler (newValue) {
                this.listSync = _.cloneDeep(newValue)
            }
        }
    },
    created () {},
    methods: {
        getDateRangeDisplay,
        handle (type, data) {
            this.$emit('todo-handle', type, data)
        },
        async handleComplete (item, index) {
            await todoSvc.todoComplete({
                todoId: item.id
            })
            this.$emit('handle-refresh')
            bus.$emit('refrsh-handled-list')
            this.$message.success('处理完成！')
        },
        // 点击名称
        titleClick (item) {
            console.log(item, '====点击名称')
            this.$emit('todo-handle', '编辑', item)
        },
        closeMenu () {
            this.visible = false
        },
        rightShow (item, e) {
            this.$emit('rightClickShowMenu', item, e)
        },
        onEnd () {
            console.log(this.listSync)
            this.$emit('todo-sort', this.listSync)
        },
        moveToStick (id) {
            this.$emit('moveToStick', id)
        },
        cancelStick (id) {
            this.$emit('cancelStick', id)
        }
    }
}
</script>
<style lang="less" scoped>
.todo-list {
    i.icon-yiwancheng {
        color: #52c123;
    }

    i.icon-yigezhi {
        color: #e6a23c;
    }

    i.icon-yiquxiao,
    i.icon-weiwancheng {
        color: #df5a68;
    }

    i.assign.icon-yiwancheng {
        color: #e6a23c;
    }

    i.icon-gengduo1 {
        cursor: pointer;
    }
    flex: 1;
    // overflow-y: auto;
    padding: 0 0.2rem;

    li {
        position: relative;
        // justify-content: space-between;
        box-sizing: border-box;
        display: flex;
        // flex-direction: column;
        // justify-content: center;
        align-items: center;
        height: 0.62rem;
        // padding: 0 .2rem;
        padding-left: 0.1rem;
        border-bottom: 1px solid #f0f0f0;

        .icon-zhidingjiaobiao {
            position: absolute;
            top: 0;
            left: 0;
            color: rgb(223, 90, 104);
        }

        span.status-icon {
            i {
                font-size: 0.2rem;
            }

            /deep/ .el-checkbox__inner {
                width: 0.2rem;
                height: 0.2rem;
                border-radius: 50%;
            }

            /deep/ .el-checkbox__inner::after {
                top: 0.04rem;
                left: 0.07rem;
            }
        }

        .middle {
            flex: 1;
            padding-left: 0.1rem;
            overflow: hidden;

            /deep/ .todo__title {
                max-width: 2.4rem;
                overflow: hidden;
                font-size: 0.16rem;
                font-weight: 400;
                line-height: 0.32rem;
                color: #606266;
                text-overflow: ellipsis;
                white-space: nowrap;
            }

            .todo-title:hover {
                color: #69acf1;
                cursor: pointer;
                background-color: #f8f8f8;
            }

            p:nth-child(2) {
                font-size: 0.14rem;
                font-weight: 400;
                color: #c0c4cc;

                i.assign {
                    margin-left: 0.05rem;
                    color: #69acf1;
                    cursor: pointer;
                }
            }
        }
    }

    li.top {
        .middle {
            p:nth-child(1) {
                font-weight: 500;
            }
        }
    }

    .v-enter,
    .v-leave-to {
        // transform: translateY(400px);
        display: none;
        opacity: 0;
    }

    .v-enter-active,
    .v-leave-active {
        transition: all 0.5s ease;
    }

    .v-move {
        /* 让元素被改变定位的时候，有一个缓动效果 */
        transition: all 0.5s ease;
    }

    .v-leave-active {
        /* 表示要被删除的元素 ，让即将被移除的元素脱离标准流，这样后面的元素就能渐渐的浮动上来 */
        position: absolute;
        transition: all 0.5s ease;
    }
}
</style>
