import { render, staticRenderFns } from "./joiner.vue?vue&type=template&id=7d47403a&scoped=true&"
import script from "./joiner.vue?vue&type=script&lang=js&"
export * from "./joiner.vue?vue&type=script&lang=js&"
import style0 from "./joiner.vue?vue&type=style&index=0&id=7d47403a&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7d47403a",
  null
  
)

export default component.exports