<template>
    <el-popover
        v-model="visible"
        trigger="click"
        :width="400"
        placement="left"
        popper-class="todo__container"
    >
        <add-todo
            ref="todoPopover"
            :is-edit="isEdit"
            :is-detail="isDetail"
            class="todo__popover"
            @handle-hidden="handleHidden"
        ></add-todo>
        <p slot="reference" class="todo__title">{{ title }}</p>
    </el-popover>
</template>

<script>
import AddTodo from './addTodo'
import todoSvc from 'services/todo'
export default {
    name: 'TodoEditPopover',
    components: { AddTodo },
    props: {
        title: {
            type: String,
            default: null
        },
        todoId: {
            type: String,
            default: null
        },
        isEdit: Boolean,
        isDetail: Boolean
    },
    data () {
        return {
            visible: false
        }
    },
    computed: {
        refId () {
            return window.localStorage.getItem('refId')
        }
    },
    watch: {
        async visible (newValue) {
            if (newValue) {
                this.$refs.todoPopover.loading = true
                try {
                    const { data = {} } = await todoSvc.getTodoDetail({
                        todoId: this.todoId
                    })
                    data.dateRange = [data.scheduleStart, data.scheduleEnd]
                    if (data.todoRepeat) {
                        data.customType = data.todoRepeat.customType
                            ? data.todoRepeat.customType
                            : null
                        data.repeatType = data.todoRepeat.repeatType
                            ? data.todoRepeat.repeatType
                            : null
                        data.workdayOnly = data.todoRepeat.workdayOnly
                            ? data.todoRepeat.workdayOnly
                            : null
                        data.repeatExpired = data.todoRepeat.repeatExpired
                            ? data.todoRepeat.repeatExpired
                            : null
                        data.repeatLimit = data.todoRepeat.repeatLimit
                            ? data.todoRepeat.repeatLimit
                            : null
                        data.repeatCount = data.todoRepeat.repeatCount
                            ? data.todoRepeat.repeatCount
                            : 0
                        data.customValue = data.todoRepeat.customValue
                            ? data.todoRepeat.customValue.split(',')
                            : null
                        if (data.todoRepeat.repeatLimit) {
                            data.customRepeatType = 2
                        } else if (data.todoRepeat.repeatExpired) {
                            data.customRepeatType = 3
                        } else {
                            data.customRepeatType = 1
                        }
                    } else {
                        data.repeatType = Number(data.repeatId)
                    }
                    this.$refs.todoPopover.form = data
                    // this.$refs.todoPopover.more = true
                    this.$refs.todoPopover.showDisplayTitlte(data.repeatType)
                    this.$refs.todoPopover.loading = false
                } catch (error) {
                    this.$refs.todoPopover.loading = false
                }
            }
        }
    },
    methods: {
        handleHidden () {
            this.visible = false
        },
        // 点击标题显示popover
        showEditPopover () {
            const refId = localStorage.getItem('refId')
            if (refId && refId !== this.todoId) {

            }
            this.visible = !this.visible
        }
    }
}
</script>
<style lang="less" scoped>
/deep/ .el-popover__reference-wrapper {
    .todo__title {
        overflow: hidden;
        font-size: 0.16rem;
        font-weight: 400;
        line-height: 0.32rem;
        color: #606266;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    .todo__title:hover {
        color: #69acf1;
        cursor: pointer;
        background-color: #f8f8f8;
    }
}

.add__container.todo__popover {
    // min-height: 3rem;
    border-color: rgba(1, 1, 1, 0);
}
</style>
<style>
.todo__container.el-popover {
    padding: 0;
}
</style>
