<template>
    <div
        ref="addTodo"
        v-loading="loading"
        class="add__container"
        :class="{
            focused: more,
            'add__container--edit': isEdit,
            'add__container--detail': isDetail,
        }"
    >
        <div class="add__quick">
            <el-input
                v-if="(isEdit || isAdd) && more"
                ref="addInput"
                v-model.trim="form.title"
                class="add__input"
                maxlength="100"
                placeholder="输入标题"
            ></el-input>
            <el-input
                v-if="(isEdit || isAdd) && !more"
                ref="addInput"
                v-model.trim="form.title"
                class="add__input"
                maxlength="100"
                placeholder="输入后回车快速创建待办"
                @keyup.enter.native="handleAdd"
            ></el-input>
            <p v-if="isDetail" class="add__quick--detail">
                {{ form.title }}
            </p>
            <div v-if="isAdd" class="add__expand" @click.prevent="handleExpand">
                {{ more ? '收起' : '展开' }}
            </div>
            <div v-if="showSaveBtn" class="add__btn">
                <el-button
                    size="mini"
                    type="primary"
                    :disabled="!form.title"
                    @click="handleAdd"
                >
                    保存
                </el-button>
            </div>
        </div>
        <transition name="fade-transform" mode="out-in">
            <div v-show="more || isEdit || isDetail" class="add__more">
                <div class="add__more--main">
                    <!-- 优先级 -->
                    <priority-popover
                        ref="priorityPopover"
                        :form="form"
                        :is-detail="isDetail"
                        @transformData="transformData"
                        @closeAllPopovers="closeAllPopovers"
                    ></priority-popover>
                    <el-divider direction="vertical"></el-divider>
                    <!-- 待办重复定义 -->
                    <schedule-popover
                        ref="schedulePopover"
                        :form="form"
                        :is-detail="isDetail"
                        @transformData="transformData"
                    ></schedule-popover>
                    <el-divider direction="vertical"></el-divider>
                    <!-- 与会人员 -->
                    <joiner-popover
                        ref="joinerPopover"
                        :form="form"
                        :is-detail="isDetail"
                        @transformData="transformData"
                    ></joiner-popover>
                </div>
            </div>
        </transition>
        <div v-if="more || isEdit || isDetail" class="add__description">
            <el-input
                v-if="!isDetail"
                v-model="form.description"
                type="textarea"
                placeholder="描述"
                maxlength="500"
                :rows="isAdd ? 3 : 8"
            >
            </el-input>
            <p v-else class="add__description--detail">
                {{ form.description }}
            </p>
        </div>
    </div>
</template>

<script>
import { getDateRangeDisplay } from 'utils/date'
import JoinerPopover from './popover/joiner'
import schedulePopover from './popover/schedule'
import priorityPopover from './popover/priority'
import _ from 'lodash'
import todoSvc from 'services/todo'
import bus from 'utils/eventBus'

export default {
    name: 'AddTodoInput',
    components: { JoinerPopover, schedulePopover, priorityPopover },
    filters: {
        priorityFilter: (value) => {
            switch (value) {
            case 10:
                return '低'
            case 20:
                return '中'
            case 30:
                return '高'
            }
        }
    },
    props: {
        showFull: Boolean, // 是否显示全部
        isAdd: Boolean, // 添加
        isEdit: Boolean, // 编辑
        isDetail: Boolean // 详情
    },
    data () {
        return {
            loading: false,
            more: false,
            tempTitle: '',
            form: {
                priority: 20,
                title: null,
                description: '',
                repeatType: 0,
                dateRange: [new Date(), new Date()],
                eidtable: true,
                customRepeatType: 1
            }
        }
    },
    computed: {
        showSaveBtn () {
            return this.isEdit || (this.isAdd && this.more)
        }
    },
    watch: {
        more (newValue) {
            if (newValue) {
                this.$refs.addInput.focus()
            } else {
                if (!this.isEdit || !this.isDetail) {
                    // 展开时重置 优先级、重复、与会人数据
                    this.$nextTick(() => {
                        this.$refs.joinerPopover.resetDisplay()
                        this.$refs.schedulePopover.dataReset()
                        this.$refs.priorityPopover.dataReset()
                    })
                    // 重置表单数据
                    this.clearInputvalue()
                    // 展开时保留待办标题
                    // this.form.title = this.form.title ? this.tempTitle : ''
                }
            }
            this.form.title = this.tempTitle
        }
    },
    created () {},
    methods: {
        getDateRangeDisplay,
        async handleAdd () {
            console.log(this.form)
            // 快速创建时的参数
            let param = {
                title: this.form.title,
                priority: this.form.priority,
                description: this.form.description,
                scheduleStart: this.$moment(this.form.dateRange[0]).format(
                    'YYYY-MM-DD'
                ),
                scheduleEnd: this.$moment(this.form.dateRange[1]).format(
                    'YYYY-MM-DD'
                ),
                repeatType: 0
            }
            // 快速创建时 重复类型默认为0
            if (this.isAdd && !this.more) {
                param.repeatType = 0
            } else {
                param.customType = this.form.customType // 自定义类型：13每周，14每月，15每年
                param.repeatType = this.form.repeatType
                param.workdayOnly = this.form.workdayOnly
                param.repeatExpired = this.form.repeatExpired
                param.repeatLimit = this.form.repeatLimit
                param.customValues = this.form.customValue
                param.customRepeatType = this.form.customRepeatType
                param.executor = this.form.executor
                param.participants = this.form.participants
            }
            // 修改待办时 需要待办id
            if (this.isEdit) {
                param.todoId = this.form.id
            }
            console.log(param, '======param')
            try {
                let apiName = this.isEdit ? 'todoEdit' : 'addTodo'
                await todoSvc[apiName](param)
                this.$message.success('保存成功！')
                // 保存成功后 需要刷新待办列表
                bus.$emit('refresh-todo-list')
                // 触发日计划弹框 刷新数据列表
                bus.$emit('refresh-all-todo-list')
                // 刷新今日已处理列表
                bus.$emit('refrsh-handled-list')
                this.closeAllPopovers()
                if (!this.isEdit) {
                    this.clearInputvalue()
                    this.tempTitle = ''
                    this.more = false
                } else {
                    // 保存成功后 需要隐藏修改popover浮层
                    this.$emit('handle-hidden')
                }
            } catch (error) {
                console.log(error)
            }
        },
        handleExpand () {
            this.tempTitle = this.form.title
            this.more = !this.more
            this.$refs.addInput.focus()
        },
        priorityChange () {
            console.log(this.$refs.priority)
            this.$refs.priority.doClose()
        },
        clearInputvalue () {
            this.form = {
                priority: 20,
                title: null,
                description: '',
                repeatType: 0,
                dateRange: [new Date(), new Date()]
            }
            this.$refs.addInput.focus()
        },
        transformData (data) {
            console.log(data)
            // this.form = _.cloneDeep(data)
            this.form = Object.assign({}, this.form, data)
        },
        // 触发子组件 显示重复类型label
        showDisplayTitlte (val) {
            this.$nextTick(() => {
                this.$refs.schedulePopover.getRepeatLabel(val)
                this.$refs.schedulePopover.cloneFormData()
                this.$refs.priorityPopover.cloneFormData()
                this.$refs.joinerPopover.cloneFormData()
            })
        },
        // 关闭所有浮层
        closeAllPopovers () {
            if (this.$refs.schedulePopover) {
                console.log(this.$refs.schedulePopover)
                this.$refs.schedulePopover.visible = false
            }
            if (this.$refs.joinerPopover) {
                this.$refs.joinerPopover.visible = false
            }
        }
    }
}
</script>
<style lang="less" scoped>
.add {
    &__container {
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        // height: 0.44rem;
        border: 1px solid #dcdfe6;
        border-radius: 0.05rem;
    }

    &__container--edit {
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
    }

    &__quick {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    &__description--detail,
    &__quick--detail {
        padding: 0.15rem 0.1rem;
        line-height: 1.5;
    }

    &__container.focused,
    &__container:hover {
        // border: 1px solid #69acf1;
        border-color: #69acf1;
    }

    &__input {
        flex: 1;

        /deep/ .el-input__inner {
            border: none;
            // border-radius: 0.4rem;
            border-radius: 0.05rem;
        }
    }

    &__expand {
        padding: 0 0.08rem;
        font-size: 0.14rem;
        color: #69acf1;
        text-align: center;
        cursor: pointer;
    }

    &__more {
        display: flex;
        justify-content: space-between;
        width: 100%;
        padding-top: 0.08rem;

        .add__more--main {
            display: flex;
            align-items: center;
            padding: 0.05rem 0.1rem;
        }

        .iconfont,
        .el-icon-date {
            font-size: 0.2rem;
            // color: #dcdfe6;
        }

        /deep/ .el-date-editor,
        /deep/ .el-select {
            width: 2.4rem;
        }

        .el-divider--vertical {
            margin: 0 0.04rem;
        }
    }

    &__btn {
        margin-right: 0.05rem;
    }

    &__description {
        flex: 1;
        overflow: auto;

        /deep/ .el-textarea {
            height: 100%;
        }

        /deep/ .el-textarea__inner {
            height: 100%;
            border: none;
            border-radius: 0.05rem;
        }
    }
}
</style>
