<template>
    <el-popover
        v-if="!isDetail"
        v-model="visible"
        class="schedule__popover"
        placement="top"
    >
        <el-form
            ref="popoverForm"
            label-width="80px"
            class="schedule__form"
            :model="popoverData"
            :show-message="false"
        >
            <el-form-item label="时间段">
                <el-date-picker
                    v-model="popoverData.dateRange"
                    style="width: 240px;"
                    type="daterange"
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                    format="yyyy-MM-dd"
                    value-format="yyyy-MM-dd"
                    :clearable="false"
                >
                </el-date-picker>
            </el-form-item>
            <el-form-item label="重复">
                <el-select
                    v-model="popoverData.repeatType"
                    style="width: 240px;"
                >
                    <el-option
                        v-for="item in repeatTypeOption"
                        :key="item.value"
                        :value="item.value"
                        :label="item.label"
                    >
                        {{ item.label }}
                    </el-option>
                </el-select>
            </el-form-item>
            <template v-if="popoverData.repeatType === 99">
                <el-form-item label="重复周期" prop="customType" required>
                    <el-select
                        v-model="popoverData.customType"
                        style="width: 240px;"
                        @change="handleCustomTypeChange"
                    >
                        <el-option
                            v-for="item in customRepeatTypeOption"
                            :key="item.value"
                            :value="item.value"
                            :label="item.label"
                        >
                            {{ item.label }}
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="重复时间" prop="customValue" required>
                    <el-date-picker
                        v-if="popoverData.customType === 15"
                        v-model="popoverData.customValue[0]"
                        type="date"
                        placeholder="选择日期"
                        format="MM-dd"
                        value-format="MMdd"
                        style="width: 240px;"
                    >
                    </el-date-picker>
                    <el-select
                        v-else
                        v-model="popoverData.customValue"
                        style="width: 240px;"
                        multiple
                        collapse-tags
                        :disabled="!popoverData.customType"
                    >
                        <el-option
                            v-for="item in popoverData.customType === 13
                                ? weekOptions
                                : monthOptions"
                            :key="item.value"
                            :value="item.value"
                            :label="item.label"
                        >
                            {{ item.label }}
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="重复次数" prop="customRepeatType" required>
                    <el-select
                        v-model="popoverData.customRepeatType"
                        style="width: 240px;"
                    >
                        <el-option
                            v-for="item in repeatCountOptions"
                            :key="item.value"
                            :value="item.value"
                            :label="item.label"
                        >
                            {{ item.label }}
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item
                    v-if="popoverData.customRepeatType === 2"
                    prop="repeatLimit"
                    required
                    label="周期数"
                >
                    <el-input-number
                        v-model="popoverData.repeatLimit"
                        style="width: 240px;"
                        controls-position="right"
                        :min="1"
                    ></el-input-number>
                </el-form-item>
                <el-form-item
                    v-if="popoverData.customRepeatType === 3"
                    prop="repeatExpired"
                    required
                    label="截止日期"
                >
                    <el-date-picker
                        v-model="popoverData.repeatExpired"
                        type="date"
                        placeholder="选择日期"
                        value-format="yyyy-MM-dd"
                        style="width: 240px;"
                    >
                    </el-date-picker>
                </el-form-item>
                <el-row class="schedule__count">
                    <el-col :span="12">
                        <!-- <el-form-item label="仅工作日">
                            <el-switch
                                v-model="popoverData.workdayOnly"
                                active-color="#13ce66"
                                inactive-color="#ff4949"
                            >
                            </el-switch>
                        </el-form-item> -->
                        <el-checkbox v-model="popoverData.workdayOnly">仅工作日</el-checkbox>
                    </el-col>
                    <!-- <el-col :span="12">
                        共计
                        <b>{{ count }}</b>
                        次
                    </el-col> -->
                </el-row>
            </template>
            <div class="schedule__btns">
                <el-button
                    class="custom-button"
                    size="mini"
                    @click="handleCancel"
                >
                    取消
                </el-button>
                <el-button
                    class="custom-button"
                    type="primary"
                    size="mini"
                    @click="handleConfirm"
                >
                    确定
                </el-button>
            </div>
        </el-form>
        <span slot="reference" class="schedule__display">
            <i class="el-icon-date"></i>
            <span>{{ dateString }}</span>
            <el-tooltip
                v-if="form.repeatType"
                class="item"
                effect="dark"
                placement="top"
            >
                <div slot="content">
                    {{ displayStr }}
                    <template v-if="popoverData.repeatType === 99">
                        <span v-if="popoverData.customType">
                            <br />
                            重复周期：{{
                                popoverData.customType
                                    | customTypeFilter(customRepeatTypeOption)
                            }}
                        </span>
                        <span v-if="popoverData.customValue">
                            <br />
                            重复时间：{{
                                popoverData.customValue
                                    | customValueFilter(
                                        popoverData.customType,
                                        weekOptions,
                                        monthOptions
                                    )
                            }}
                        </span>
                        <span v-if="popoverData.customRepeatType">
                            <br />
                            重复次数：{{
                                popoverData.customRepeatType
                                    | customRepeatTypeFilter(repeatCountOptions)
                            }}
                        </span>
                        <span
                            v-if="
                                popoverData.customRepeatType === 2 &&
                                    popoverData.repeatLimit
                            "
                        >
                            <br />
                            周期数：{{ popoverData.repeatLimit }}
                        </span>
                        <span
                            v-if="
                                popoverData.customRepeatType === 3 &&
                                    popoverData.repeatExpired
                            "
                        >
                            <br />
                            截止日期：{{ popoverData.repeatExpired }}
                        </span>
                        <span>
                            <br />
                            仅工作日：{{
                                popoverData.workdayOnly | workdayOnlyFilter
                            }}
                        </span>
                        <!-- <span>
                            <br />
                            共计：{{ count }} 天
                        </span> -->
                    </template>
                </div>
                <i class="el-icon-refresh"></i>
            </el-tooltip>
        </span>
    </el-popover>
    <span v-else class="schedule__display">
        <el-tooltip
            v-if="form.repeatId!=0"
            class="item"
            effect="dark"
            placement="top"
        >
            <div slot="content">
                {{ displayStr }}
                <template v-if="popoverData.repeatType === 99">
                    <span v-if="popoverData.customType">
                        <br />
                        重复周期：{{
                            popoverData.customType
                                | customTypeFilter(customRepeatTypeOption)
                        }}
                    </span>
                    <span v-if="popoverData.customValue">
                        <br />
                        重复时间：{{
                            popoverData.customValue
                                | customValueFilter(
                                        popoverData.customType,
                                        weekOptions,
                                        monthOptions
                                    )
                        }}
                    </span>
                    <span v-if="popoverData.customRepeatType">
                        <br />
                        重复次数：{{
                            popoverData.customRepeatType
                                | customRepeatTypeFilter(repeatCountOptions)
                        }}
                    </span>
                    <span
                        v-if="
                            popoverData.customRepeatType === 2 &&
                                popoverData.repeatLimit
                        "
                    >
                        <br />
                        周期数：{{ popoverData.repeatLimit }}
                    </span>
                    <span
                        v-if="
                            popoverData.customRepeatType === 3 &&
                                popoverData.repeatExpired
                        "
                    >
                        <br />
                        截止日期：{{ popoverData.repeatExpired }}
                    </span>
                    <span>
                        <br />
                        仅工作日：{{
                            popoverData.workdayOnly | workdayOnlyFilter
                        }}
                    </span>
                    <!-- <span>
                            <br />
                            共计：{{ count }} 天
                        </span> -->
                </template>
            </div>
            <span>
                <i class="el-icon-date"></i>
                <span>{{ dateString }}</span>
                <i class="el-icon-refresh"></i>
            </span>
        </el-tooltip>
        <span v-else>
            <i class="el-icon-date"></i>
            <span>{{ dateString }}</span>
        </span>
    </span>
</template>

<script>
import { getDateRangeDisplay } from 'utils/date'
import _ from 'lodash'
export default {
    name: 'SchedulePopover',
    components: {},
    filters: {
        customTypeFilter: (val, option) => {
            return option.find((item) => item.value == val).label
        },
        workdayOnlyFilter: (val) => {
            return val ? '是' : '否'
        },
        customValueFilter: (val, type, option1, option2) => {
            let str = ''
            if (val && val.length > 0) {
                if (type === 15) {
                    str = val[0].substr(0, 2) + '-' + val[0].substr(2)
                } else if (type === 13) {
                    let arr = []
                    val.forEach((item) => {
                        let res = option1.find((o) => o.value == item)
                        if (res) {
                            arr.push(res.label)
                        }
                    })
                    str = arr.join(',')
                } else if (type === 14) {
                    let arr = []
                    val.forEach((item) => {
                        let res = option2.find((o) => o.value == item)
                        if (res) {
                            arr.push(res.label)
                        }
                    })
                    str = arr.join(',')
                }
            }
            return str
        },
        customRepeatTypeFilter: (val, option) => {
            return option.find((item) => item.value == val).label
        }
    },
    props: {
        form: {
            type: Object,
            default: null
        },
        isDetail: Boolean
    },
    data () {
        return {
            visible: false,
            // 重复类型下拉选项
            repeatTypeOption: [
                {
                    value: 0,
                    label: '不重复'
                },
                {
                    value: 1,
                    label: '每天'
                },
                {
                    value: 2,
                    label: '每工作日'
                },
                {
                    value: 3,
                    label: '每周'
                },
                {
                    value: 4,
                    label: '每月'
                },
                {
                    value: 5,
                    label: '每年'
                },
                {
                    value: 99,
                    label: '自定义'
                }
            ],
            // 自定义重复类型下拉选项
            customRepeatTypeOption: [
                {
                    value: 13,
                    label: '每周'
                },
                {
                    value: 14,
                    label: '每月'
                },
                {
                    value: 15,
                    label: '每年'
                }
            ],
            // 重复次数下拉
            repeatCountOptions: [
                {
                    value: 1,
                    label: '一直重复'
                },
                {
                    value: 2,
                    label: '限定周期数'
                },
                {
                    value: 3,
                    label: '限定截止日期'
                }
            ],
            popoverData: {
                dateRange: [], // 选择的时间段
                repeatType: null // 重复类型值
            },
            displayStr: '',
            weekOptions: [],
            monthOptions: []
        }
    },
    computed: {
        dateString () {
            return this.getDateRangeDisplay(this.form.dateRange)
        },
        count () {
            return 0
        }
    },
    watch: {
        visible (newValue) {
            if (newValue) {
                this.cloneFormData()
            }
        }
    },
    created () {
        const weekStr = ['一', '二', '三', '四', '五', '六', '日']
        for (let index = 0; index < 7; index++) {
            let obj = {
                value: index + 1 + '',
                label: `星期${weekStr[index]}`
            }
            this.weekOptions.push(obj)
        }
        for (let index = 0; index < 31; index++) {
            let obj = {
                value: index + 1 + '',
                label: index + 1 + ''
            }
            this.monthOptions.push(obj)
        }
        this.monthOptions.push({
            value: '32',
            label: '最后一天'
        })
    },
    methods: {
        getDateRangeDisplay,
        handleConfirm () {
            this.$refs.popoverForm.validate((valid) => {
                if (valid) {
                    this.getRepeatLabel(this.popoverData.repeatType)
                    this.$emit('transformData', this.popoverData)
                    this.visible = false
                } else {
                    console.log('error submit')
                }
            })
        },
        handleCancel () {
            this.visible = false
        },
        // 自定义重复周期变化
        handleCustomTypeChange () {
            this.popoverData.customValue = []
        },
        // 根据重复类型value 查找重复类型label
        getRepeatLabel (value) {
            this.displayStr = this.repeatTypeOption.find(
                (item) => item.value === value
            ).label
        },
        // data reset
        dataReset () {
            this.popoverData = {
                dateRange: [], // 选择的时间段
                repeatType: null // 重复类型值
            }
            this.displayStr = ''
            // this.customValue = null
        },
        // 克隆赋值
        cloneFormData () {
            this.popoverData = _.cloneDeep(this.form)
        }
    }
}
</script>
<style lang="scss" scoped>
.schedule {
    &__form {
        padding: 0 0.2rem;
    }

    &__display {
        display: flex;
        align-items: center;
        // margin-left: 0.08rem;
        font-size: 0.14rem;
        cursor: pointer;

        i {
            margin-right: 0.05rem;
            font-size: 0.16rem;
        }

        i.el-icon-refresh {
            margin-left: 0.05rem;
        }
    }

    &__btns {
        text-align: right;
    }

    &__count {
        display: flex;
        align-items: center;
        padding-bottom: 0.15rem;

        .el-form-item {
            margin: 0;
        }

        .el-col:nth-child(1) {
            padding-left: 10px;
        }

        .el-col:nth-child(2) {
            text-align: right;
        }
    }
}
</style>
