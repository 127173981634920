<template>
    <div class="handled__todos">
        <el-collapse v-model="activeNames">
            <el-collapse-item :title="title" name="1">
                <template v-if="handledTodos.length > 0">
                    <todo-item
                        v-for="(item, index) in handledTodos"
                        :key="index"
                        :is-edit="true"
                        :item-data="item"
                        sign="今日已处理"
                        :right-dropdown-handles="handles"
                    >
                    </todo-item>
                </template>
                <Empty v-else :show-img="false" />
            </el-collapse-item>
        </el-collapse>
    </div>
</template>

<script>
import todoItem from './components/todoItem'
import todoSvc from 'services/todo'
import Empty from 'components/empty'
import bus from 'utils/eventBus'

export default {
    name: 'HandledTodos',
    components: { todoItem, Empty },
    data () {
        return {
            activeNames: [],
            handledTodos: [], // 已处理的待办
            title: '今日已处理',
            handles: [
                { label: '重新打开', status: null },
                { label: '置顶', status: null }
                // { label: '搁置', status: null }
            ]
        }
    },
    watch: {
        activeNames (newValue, oldValue) {
            // this.title = newValue.length > 0 ? '隐藏已处理' : '显示已处理'
            this.$emit('notice-expand', newValue.length > 0)
        }
    },
    created () {
        this.getHandledTodos()
    },
    mounted () {
        bus.$on('refrsh-handled-list', this.getHandledTodos)
    },
    methods: {
    // 查询已经解决的待办
        async getHandledTodos () {
            const { data = [] } = await todoSvc.getHandledTodos({
                processedOnToday: true
            })
            this.handledTodos = data
        },
        // 重新打开
        async reOpen (item) {
            try {
                this.loading = true
                await todoSvc.reOpenTodo({
                    id: item.id
                })
                this.$message.success('操作成功！')
                this.getHandledTodos()
                bus.$emit('refrsh-today-list')
                this.loading = false
            } catch (error) {
                this.loading = false
            }
        },
        async toTop (item) {
            try {
                // 移入置顶
                await todoSvc.moveToTop({
                    todoId: item.id
                })
                this.getHandledTodos()
                bus.$emit('refrsh-today-list')
                this.$message.success('操作成功！')
            } catch (error) {
                console.log(error)
            }
        }
    }
}
</script>
<style lang="scss" scoped>
.handled__todos {
    height: 0.8rem;

    ::v-deep .el-collapse {
        padding: 0 0.2rem;
        border: none;

        .el-collapse-item__header {
            color: #409eff;
        }

        .el-collapse-item__wrap,
        .el-collapse-item__header {
            border: none;
        }

        .assign__item {
            padding-left: 0.1rem;
        }
    }
}
</style>
